import actionTypes from '../../types/actionTypes';
import { privateOrderAPI } from '../../webapi/private';
import immutable from 'immutable';
import { Dispatch } from 'redux';

export const countOrders =
  (dispatch: Dispatch<any>) =>
  async (silent: boolean = false) => {
    const setLoadingState = () => ({
      data: immutable.fromJS({ status: 'LOADING' }),
      type: actionTypes.ORDERS_COUNT_INIT,
    });

    const setErrorState = (error) => ({
      data: immutable.fromJS({ status: 'ERROR' }),
      type: actionTypes.ORDERS_COUNT_FAIL,
    });

    const setSuccessState = (response) => {
      return {
        data: immutable.fromJS({ response, status: 'SUCCESS' }),
        type: actionTypes.ORDERS_COUNT_SUCCESS,
      };
    };

    dispatch(setLoadingState());
    try {
      const response = await privateOrderAPI.countOrders(silent);
      return dispatch(setSuccessState(response));
    } catch (err) {
      return dispatch(setErrorState(err));
    }
  };
