import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';
import { fromJS } from 'immutable';

export interface SearchOptsType {
  keywords?: string;
  categoryIds?: string | number | void;
  ownerId?: number;
  selectedStartDate?: Date;
  selectedEndDate?: Date;
  pageIndex?: any;
  bounds?: { northEast: { lat: number; lng: number }; southWest: { lat: number; lng: number } };
  pageSize?: number;
}

const dateToISO = (date: Date) => {
  const month = date.getMonth() + 1;
  return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${date.getDate()}`;
};

export const searchAPI = {
  fetchSearchResult({
    keywords,
    categoryIds,
    selectedStartDate,
    selectedEndDate,
    pageIndex,
    bounds,
    ownerId,
    pageSize = 24,
  }: SearchOptsType) {
    const adjustedPageIndex = pageIndex < 1 ? null : pageIndex;
    return new Promise((resolve, reject) => {
      let boundsNorthWestLatitude = undefined;
      let boundsNorthWestLongitude = undefined;
      let boundsSouthEastLatitude = undefined;
      let boundsSouthEastLongitude = undefined;

      if (bounds) {
        boundsNorthWestLatitude = bounds.northEast.lat;
        boundsNorthWestLongitude = bounds.southWest.lng;
        boundsSouthEastLatitude = bounds.southWest.lat;
        boundsSouthEastLongitude = bounds.northEast.lng;
      }

      const uri = buildUrl('/v2/product-listings/search', {
        keywords,
        categoryIds,
        selectedStartDate: selectedStartDate ? dateToISO(selectedStartDate) : null,
        selectedEndDate: selectedEndDate ? dateToISO(selectedEndDate) : null,
        pageIndex: adjustedPageIndex,
        pageSize,
        boundsNorthWestLatitude,
        boundsNorthWestLongitude,
        boundsSouthEastLatitude,
        boundsSouthEastLongitude,
        ownerId,
      });

      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchAutoCompleteSuggestions(query: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/product-listings/autocomplete', {
        query,
      });

      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchListingsByVendor(vendorID: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/product-listings/search', {
        vendorID,
      });

      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchListingsByOwner(ownerId: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/product-listings/search', {
        ownerId,
      });

      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchFavoriteListings() {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/product-listings/search', { isFavorite: true });

      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  async getRelatedCategoryProducts({
    categoryId,
    lat: latitude,
    lon: longitude,
  }: {
    categoryId: number;
    lat: number;
    lon: number;
  }) {
    const { body } = await request(
      {
        uri: buildUrl('/v2/product-listings/search', {
          latitude,
          longitude,
          categoryIds: categoryId,
          pageIndex: 0,
          pageSize: 10,
        }),
        method: 'GET',
      },
      true
    );
    const json = JSON.parse(body);

    return fromJS(json.productListings);
  },
};

export default searchAPI;
