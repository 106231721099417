import { Injectable } from '@angular/core';

import config_se from '../../assets/config/hygglo_se.config.json';
import config_no from '../../assets/config/hygglo_no.config.json';
import config_dk from '../../assets/config/hygglo_dk.config.json';
import config_fi from '../../assets/config/hygglo_fi.config.json';

@Injectable()
export class AppConfigService {
  private appConfig: {
    country: string;
    domain: string;
    defaultLang: string;
    email: string;
    address: string;
    orgnumber: string;
    social_media: {
      facebook: string;
      instagram: string;
      twitter: string;
    };
    index: {
      title: string;
      description: string;
      ogimage: string;
    };
  };

  constructor() {}

  public async loadAppConfig() {
    const currentTLD = location.hostname.substr(location.hostname.search('.(?:.(?!.))+$'));
    try {
      this.appConfig = ((tld) => {
        switch (tld) {
          case 'no':
            return config_no;
          case 'dk':
            return config_dk;
          case 'fi':
            return config_fi;
          default:
            return config_se;
        }
      })(currentTLD);
      localStorage.setItem('country', this.appConfig.country);
    } catch (err) {
      console.warn('Error loading app-config.json');
    }
  }

  get config() {
    return this.appConfig;
  }

  get country(): string {
    return this.config.country;
  }
}
