import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({
  vendor: {},
  customer: {},
});

const orders = (state = initialState, action = { type: undefined, data: undefined }) => {
  let orderId;
  let orders;

  switch (action?.type) {
    //    case actionTypes.STORAGE_FETCH_SUCCESS:
    //      return action.data.getIn(['response', 'orders']) || fromJS({});
    case actionTypes.ORDERS_FETCH_INIT:
    case actionTypes.ORDERS_FETCH_FAIL:
      return state.set('status', action.data.get('status'));

    case actionTypes.ORDERS_FETCH_SUCCESS: {
      const response = action.data.get('response');
      const role = response.get('role');
      const filter = response.get('filter');
      const offset = response.get('offset');
      const orders = response.get('orders');

      const newState = state
        .set('status', response.get('status'))
        .setIn(
          [role, filter],
          state.getIn([role, filter], fromJS([])).slice(0, offset).concat(orders)
        );
      return newState;
    }

    case actionTypes.CLEAR_STATE:
    case actionTypes.ORDERS_CLEAR:
      return initialState;
    case actionTypes.ORDER_ADD_MSG:
      orderId = parseInt(action.data.get('id'), 10);
      orders = state.getIn(['response', 'orders']);

      if (!orders) {
        return state;
      }

      return state.setIn(
        ['response', 'orders'],
        orders.map((order) =>
          order.get('id') === orderId ? order.set('isArchived', false) : order
        )
      );

    case actionTypes.ORDER_ARCHIVE:
      orderId = parseInt(action.data.get('id'), 10);
      orders = state.getIn(['response', 'orders']);

      if (!orders) {
        return state;
      }

      return state.setIn(
        ['response', 'orders'],
        orders.map((order) => (order.get('id') === orderId ? order.set('isArchived', true) : order))
      );

    default:
      return state;
  }
};

export default orders;
