import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

const privateNotificationsAPI = {
  /* eslint-disable  */
  fetchNotifications() {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.fetchNotifications();
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'fetch notifications'
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  /* eslint-disable */
  markAsConsumed(referenceId: string, notificationType: string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.fetchNotifications();
      const body = {
        referenceId,
        notificationType
      };
      const options = {
        uri,
        json: body,
        method: 'PUT',
        responseType: 'json',
        intent: 'mark notifications as consumed'
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  }
};

export default privateNotificationsAPI;
