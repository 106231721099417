import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { Dispatch } from 'redux';
import { categoriesAPI } from 'src/webapi/v2';

export const fetchCategory =
  (dispatch: Dispatch<any>) => async (slug: string, silent?: boolean, location?: string) => {
    const setLoadingState = () => {
      return {
        data: fromJS({
          slug,
          status: 'LOADING',
        }),
        type: actionTypes.PRODUCT_FETCH_INIT,
      };
    };

    const setErrorState = (error) => {
      return {
        data: fromJS({
          slug,
          error,
          status: 'ERROR',
        }),
        type: actionTypes.PRODUCT_FETCH_FAIL,
      };
    };

    const setSuccessState = (response) => {
      return {
        data: fromJS({
          slug,
          response: response,
          status: 'SUCCESS',
        }),
        type: actionTypes.PRODUCT_FETCH_SUCCESS,
      };
    };

    dispatch(setLoadingState());
    try {
      const response = await categoriesAPI.fetchCategory(slug, silent, location);
      dispatch(setSuccessState(response));
      return response;
    } catch (err) {
      dispatch(setErrorState(err));
      return err;
    }
  };
