declare let localStorage: any;

/* eslint-disable no-console */
const getTokens = (): { access_token: string; refresh_token: string } | undefined => {
  try {
    const response = localStorage.getItem('tokens');
    const tokens = JSON.parse(response);
    return tokens && tokens.access_token && tokens.refresh_token ? tokens : undefined;
  } catch (error) {
    return null;
  }
};

export default getTokens;
