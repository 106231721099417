declare let localStorage: any;

const getCurrentLocation = (): any => {
  let currentPosition;

  try {
    let currentPositionString = localStorage.getItem('currentPosition');
    currentPosition = JSON.parse(currentPositionString);
    return currentPosition;
  } catch (error) {
    return null;
  }
};

const addHeaders = (settings): any => {
  if (!settings.simple) {
    let currentPosition = getCurrentLocation();

    if (currentPosition) {
      settings.headers['Geo-Position'] =
        currentPosition.latitude + ';' + currentPosition.longitude + ';';
    }
  }

  settings.headers['Accept-Language'] = localStorage.getItem('language');

  return settings;
};

export default addHeaders;
