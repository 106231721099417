import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

const privatePaymentsAPI = {
  cancelPaidOrder(orderId: any, refundReason?: string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.cancelPaidOrder(orderId);
      const options = {
        json: {
          refundReason
        },
        uri,
        method: 'POST',
        intent: 'cancel paid order'
      };
      request(options, false)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  }
};

export default privatePaymentsAPI;
