import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { productAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const fetchMyProduct = (dispatch: Dispatch<any>) => (id: number, silent?: boolean) => {
  const setLoadingState = () => {
    return {
      data: fromJS({
        id,
        status: 'LOADING'
      }),
      type: actionTypes.MY_PRODUCT_FETCH_INIT
    };
  };

  const setErrorState = (error) => {
    return {
      data: fromJS({
        id,
        status: 'ERROR'
      }),
      type: actionTypes.MY_PRODUCT_FETCH_FAIL
    };
  };

  const setSuccessState = (response) => {
    return {
      data: fromJS({
        id,
        response: response,
        status: 'SUCCESS'
      }),
      type: actionTypes.MY_PRODUCT_FETCH_SUCCESS
    };
  };

  dispatch(setLoadingState());
  productAPI
    .fetchMyProduct(id, silent)
    .then(
      (response): any => {
        dispatch(setSuccessState(response));
      }
    )
    .catch(
      (err): any => {
        dispatch(setErrorState(err));
      }
    );
};
