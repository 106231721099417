import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { Dispatch } from 'redux';

export const addOrderMsg = (dispatch: Dispatch<any>) => (id: any, msg: any) => {
  return dispatch({
    data: fromJS({
      id,
      msg
    }),
    type: actionTypes.ORDER_ADD_MSG
  });
};
