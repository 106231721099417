const actionTypes = {
  ACCOUNT_LOGIN_FAIL: 'ACCOUNT_LOGIN_FAIL',
  ACCOUNT_LOGIN_INIT: 'ACCOUNT_LOGIN_INIT',
  ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',

  ACCOUNT_UPDATE_INFO: 'ACCOUNT_UPDATE_INFO',

  BOOTSTRAP_FETCH_FAIL: 'BOOTSTRAP_FETCH_FAIL',
  BOOTSTRAP_FETCH_INIT: 'BOOTSTRAP_FETCH_INIT',
  BOOTSTRAP_FETCH_SUCCESS: 'BOOTSTRAP_FETCH_SUCCESS',

  CLEAR_ACCOUNT_STATE: 'CLEAR_ACCOUNT_STATE',
  CLEAR_NEW_ACCOUNT_STATE: 'CLEAR_NEW_ACCOUNT_STATE',
  CLEAR_NEW_PRODUCT_STATE: 'CLEAR_NEW_PRODUCT_STATE',
  CLEAR_NOTIFICATIONS_STATE: 'CLEAR_NOTIFICATIONS_STATE',
  CLEAR_STATE: 'CLEAR_STATE',

  MY_PRODUCTS_FETCH_FAIL: 'MY_PRODUCTS_FETCH_FAIL',
  MY_PRODUCTS_FETCH_INIT: 'MY_PRODUCTS_FETCH_INIT',
  MY_PRODUCTS_FETCH_SUCCESS: 'MY_PRODUCTS_FETCH_SUCCESS',

  NEWACCOUNT_UPDATE_INFO: 'NEWACCOUNT_UPDATE_INFO',

  NEWPRODUCT_ADD_IMAGE: 'NEWPRODUCT_ADD_IMAGE',
  NEWPRODUCT_DELETE_IMAGE: 'NEWPRODUCT_DELETE_IMAGE',
  NEWPRODUCT_UPDATE_INFO: 'NEWPRODUCT_UPDATE_INFO',

  ORDER_FETCH_FAIL: 'ORDER_FETCH_FAIL',
  ORDER_FETCH_INIT: 'ORDER_FETCH_INIT',
  ORDER_FETCH_SUCCESS: 'ORDER_FETCH_SUCCESS',

  ORDER_ADD_MSG: 'ORDER_ADD_MSG',
  ORDER_SAVE_DRAFT: 'ORDER_SAVE_DRAFT',
  ORDER_ARCHIVE: 'ORDER_ARCHIVE',

  ORDERS_COUNT_FAIL: 'ORDERS_COUNT_FAIL',
  ORDERS_COUNT_INIT: 'ORDERS_COUNT_INIT',
  ORDERS_COUNT_SUCCESS: 'ORDERS_COUNT_SUCCESS',
  ORDERS_CLEAR: 'ORDERS_CLEAR',

  ORDERS_FETCH_FAIL: 'ORDERS_FETCH_FAIL',
  ORDERS_FETCH_INIT: 'ORDERS_FETCH_INIT',
  ORDERS_FETCH_SUCCESS: 'ORDERS_FETCH_SUCCESS',

  PAID_OUT_ORDERS_FETCH_FAIL: 'PAID_OUT_ORDERS_FETCH_FAIL',
  PAID_OUT_ORDERS_FETCH_INIT: 'PAID_OUT_ORDERS_FETCH_INIT',
  PAID_OUT_ORDERS_FETCH_SUCCESS: 'PAID_OUT_ORDERS_FETCH_SUCCESS',

  NOTIFICATIONS_FETCH_FAIL: 'NOTIFICATIONS_FETCH_FAIL',
  NOTIFICATIONS_FETCH_INIT: 'NOTIFICATIONS_FETCH_INIT',
  NOTIFICATIONS_FETCH_SUCCESS: 'NOTIFICATIONS_FETCH_SUCCESS',

  PRODUCT_DELETE_FAIL: 'PRODUCT_DELETE_FAIL',
  PRODUCT_DELETE_INIT: 'PRODUCT_DELETE_INIT',
  PRODUCT_DELETE_SUCCESS: 'PRODUCT_DELETE_SUCCESS',

  PRODUCT_FETCH_FAIL: 'PRODUCT_FETCH_FAIL',
  PRODUCT_FETCH_INIT: 'PRODUCT_FETCH_INIT',
  PRODUCT_FETCH_SUCCESS: 'PRODUCT_FETCH_SUCCESS',

  PRODUCT_SET_FAVORITE: 'PRODUCT_SET_FAVORITE',

  FAVORITES_FETCH_FAIL: 'FAVORITES_FETCH_FAIL',
  FAVORITES_FETCH_INIT: 'FAVORITES_FETCH_INIT',
  FAVORITES_FETCH_SUCCESS: 'FAVORITES_FETCH_SUCCESS',

  MY_PRODUCT_FETCH_FAIL: 'MY_PRODUCT_FETCH_FAIL',
  MY_PRODUCT_FETCH_INIT: 'MY_PRODUCT_FETCH_INIT',
  MY_PRODUCT_FETCH_SUCCESS: 'MY_PRODUCT_FETCH_SUCCESS',

  RELATEDPRODUCTS_FETCH_FAIL: 'RELATEDPRODUCTS_FETCH_FAIL',
  RELATEDPRODUCTS_FETCH_INIT: 'RELATEDPRODUCTS_FETCH_INIT',
  RELATEDPRODUCTS_FETCH_SUCCESS: 'RELATEDPRODUCTS_FETCH_SUCCESS',

  REQUEST_ADS_FETCH_FAIL: 'REQUEST_ADS_FETCH_FAIL',
  REQUEST_ADS_FETCH_INIT: 'REQUEST_ADS_FETCH_INIT',
  REQUEST_ADS_FETCH_SUCCESS: 'REQUEST_ADS_FETCH_SUCCESS',

  SEARCH_FETCH_FAIL: 'SEARCH_FETCH_FAIL',
  SEARCH_FETCH_INIT: 'SEARCH_FETCH_INIT',
  SEARCH_FETCH_SUCCESS: 'SEARCH_FETCH_SUCCESS',
  SEARCH_CLEAR: 'SEARCH_CLEAR',

  STORAGE_FETCH_FAIL: 'STORAGE_FETCH_FAIL',
  STORAGE_FETCH_INIT: 'STORAGE_FETCH_INIT',
  STORAGE_FETCH_SUCCESS: 'STORAGE_FETCH_SUCCESS',

  SETTINGS_SET_PROP: 'SETTINGS_SET_PROP',

  CATEGORIES_SEARCH: 'CATEGORIES_SEARCH',

  TOPCATEGORIES_FETCH: 'TOPCATEGORIES_FETCH',

  FETCH_USER: 'FETCH_USER',
  FETCH_REVIEWS: 'FETCH_REVIEWS',
  FETCH_RATINGS_REVIEWS: 'FETCH_RATINGS_REVIEWS',
  FETCH_PRODUCTS_BY_USER: 'FETCH_PRODUCTS_BY_USER',
};

export default actionTypes;
