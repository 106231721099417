import { Injectable } from '@angular/core';

@Injectable()
export class DeviceIdService {
  private deviceId;

  public get(): string {
    if (!this.deviceId) {
      let deviceId = localStorage.getItem('deviceId');

      if (!deviceId) {
        deviceId = Math.random()
          .toString(16)
          .slice(2);
        localStorage.setItem('deviceId', deviceId);
      }

      this.deviceId = deviceId;
    }

    return this.deviceId;
  }
}
