<footer>
  <div class="row">
    <div class="small-12 medium-4 columns download-app">
      <h3>{{ 'FOOTER.DOWNLOAD_APP_TITLE' | translate }}</h3>
      <p>{{ 'FOOTER.DOWNLOAD_APP_SUB_TITLE' | translate }}</p>
      <div class="app-links">
        <a
          href="https://itunes.apple.com/app/apple-store/id1103737624?pt=118149474&ct=Hygglo%20Web&mt=8"
          title="{{ 'FOOTER.APP_LINKS.APPLE' | translate }}"
          class="store-button store-button-ios"
        >
          <img
            src="https://hygglo-web-static.imgix.net/apple-btn.svg?auto=format"
            alt="{{ 'FOOTER.APP_LINKS.APPLE' | translate }}"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=se.hygglo.android&referrer=utm_source%3Dhygglose%26utm_medium%3Dfooter-link"
          title="{{ 'FOOTER.APP_LINKS.GOOGEL' | translate }}"
          class="store-button store-button-android"
        >
          <img
            src="https://hygglo-web-static.imgix.net/google-btn.svg?auto=format"
            alt="{{ 'FOOTER.APP_LINKS.APPLE' | translate }}"
          />
        </a>
      </div>

      <h3 class="language">
        {{ 'FOOTER.LANGUAGE' | translate }}
        <i class="material-icons"> language </i>
      </h3>
      <div class="language">
        <a (click)="changeLanguage('en')" [ngClass]="currentLang === 'en' ? 'active' : ''">English</a>
        <a (click)="changeLanguage('da')" [ngClass]="currentLang === 'da' ? 'active' : ''">Dansk</a>
        <a (click)="changeLanguage('nb')" [ngClass]="currentLang === 'nb' ? 'active' : ''">Norsk</a>
        <a (click)="changeLanguage('fi')" [ngClass]="currentLang === 'fi' ? 'active' : ''">Suomi</a>
        <a (click)="changeLanguage('sv')" [ngClass]="currentLang === 'sv' ? 'active' : ''">Svenska</a>
      </div>
    </div>
    <div class="small-12 medium-4 columns second hide-for-small-only">
      <h3>{{ 'FOOTER.POPULAR_THINGS_TO_RENT' | translate }}</h3>
      <!--<a [routerLink]="['/category', '2211-hogtryckstvatt']">{{ 'FOOTER.RENT' | translate }} högtryckstvätt</a>
      <a [routerLink]="['/category', '2414-skapbil']">{{ 'FOOTER.RENT' | translate }} skåpbil</a>
      <a [routerLink]="['/category', '2421-takbox']">{{ 'FOOTER.RENT' | translate }} takbox</a>
      <a [routerLink]="['/category', '8414-hasttransport']">{{ 'FOOTER.RENT' | translate }} hästtransport</a>
      <a [routerLink]="['/category', '292-kamera-foto']">{{ 'FOOTER.RENT' | translate }} kamera</a>
      <a [routerLink]="['/category', '17-verktyg-byggmaskiner']">{{ 'FOOTER.RENT' | translate }} verktyg</a>
      <a [routerLink]="['/category', '22210-vertikalskarare']">{{ 'FOOTER.RENT' | translate }} vertikalskärare</a>
      <a [routerLink]="['/category', '2561-battrailer']">{{ 'FOOTER.RENT' | translate }} båttrailer</a>
      <a [routerLink]="['/category', '22212-vedklyv']">{{ 'FOOTER.RENT' | translate }} vedklyv</a>
      <a [routerLink]="['/category', '245-slapvagn']">{{ 'FOOTER.RENT' | translate }} släpvagn</a>
      <a [routerLink]="['/category', '295-tv-spel']">{{ 'FOOTER.RENT' | translate }} tv-spel</a> -->
      <a [routerLink]="['/categories']">{{ 'FOOTER.ALL_CATEGORIES' | translate }}</a>
      <a [routerLink]="['/location']">{{ 'FOOTER.HYGGLO_AREAS' | translate }}</a>
      <h3>{{ 'FOOTER.OTHER_COUNTRIES' | translate }}</h3>
      <div class="other-countries">
        <p *ngIf="this.configService.country !== 'DK'">
          Ønsker du at
          <a href="https://www.hygglo.dk">leje ting i Danmark</a>, kan du besøge
          <a href="https://www.hygglo.dk">hygglo.dk</a>
        </p>
        <p *ngIf="this.configService.country !== 'FI'">
          Jos haluat
          <a href="https://www.hygglo.fi">vuokrata tavaroita Suomessa</a>, siirry osoitteeseen
          <a href="https://www.hygglo.fi">hygglo.fi</a>
        </p>
        <p *ngIf="this.configService.country !== 'SE'">
          Om du vill
          <a href="https://www.hygglo.se">hyra saker i Sverige</a>, kan du besöka
          <a href="https://www.hygglo.se">hygglo.se</a>
        </p>
        <p *ngIf="this.configService.country !== 'NO'">
          Hvis du vill
          <a href="https://www.hygglo.no">leie ting i Norge</a>, kan du besøke
          <a href="https://www.hygglo.no">hygglo.no</a>
        </p>
      </div>
    </div>
    <div class="small-12 medium-4 columns">
      <h3 class="hide-for-small-only">{{ 'HEADER.ABOUT_US' | translate }}</h3>
      <div class="hide-for-small-only second">
        <a [routerLink]="['/om-oss']">{{ 'HEADER.TITLE' | translate }}</a>
        <a (click)="this.openFaq('5223009-general-insurance-when-renting-out')">{{ 'HEADER.INSURANCE' | translate }}</a>
        <a (click)="this.translate.openFaq()">{{ 'HEADER.FAQ' | translate }}</a>
        <a (click)="openBlog()">{{ 'ABOUT_US_PAGE.COMPONENT.BLOG' | translate }}</a>
        <a [routerLink]="['/tos']">{{ 'FOOTER.TERMS' | translate }}</a>
        <a [routerLink]="['/integritetspolicy']">{{ 'FOOTER.PRIVACYPOLICY' | translate }}</a>
        <a [routerLink]="['/om-oss/partnerskap']">{{ 'FOOTER.PARTNERSHIP' | translate }}</a>
        <a *ngIf="currentLang === 'sv'" href="http://prylsvinn.se">{{ 'FOOTER.PRYLSVINN' | translate }}</a>
        <a routerLink="/om-oss/jobb">{{ 'FOOTER.WORK_AT_HYGGLO' | translate }}</a>
      </div>
      <h3>{{ 'FOOTER.CONTACT' | translate }}</h3>
      <div>
        <dt>{{ 'FOOTER.EMAIL' | translate }}</dt>
        <dl
          ><a href="mailto:{{ email }}">{{ email }}</a></dl
        >
      </div>
      <div>
        <dt>
          <a class="blog-link" (click)="openBlog()">{{ 'ABOUT_US_PAGE.COMPONENT.BLOG' | translate }}</a>
        </dt>
      </div>
      <div>
        <dt>{{ 'FOOTER.ADDRESS' | translate }}</dt>
        <dl>{{ address }}</dl>
      </div>
      <div>
        <dt>{{ 'FOOTER.BUSINESS_NUMBER' | translate }}</dt>
        <dl>{{ orgno }}</dl>
      </div>
      <div>
        <a
          *ngIf="configService"
          href="https://www.instagram.com/{{ configService.config.social_media.instagram }}/"
          class="social ig"
          alt="Hygglo Instagram"
        ></a>
        <a
          *ngIf="configService"
          href="https://www.facebook.com/{{ configService.config.social_media.facebook }}/"
          class="social fb"
          alt="Hygglo Facebook"
        ></a>
        <a
          *ngIf="configService && configService.config.social_media.twitter"
          href="https://twitter.com/{{ configService.config.social_media.twitter }}"
          class="social tw"
          alt="Hygglo Twitter"
        ></a>
      </div>
    </div>
  </div>
</footer>
