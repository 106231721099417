import { NgRedux } from '@angular-redux/store';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './app-config.service';
import { Injectable } from '@angular/core';
import { myUserAPI } from '../../webapi/v2';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { filter, distinctUntilChanged } from 'rxjs/operators';
import { CrispArticleType, articleList } from './crisp-enum';

export const enum LANGUAGE {
  SV = 'sv',
  EN = 'en',
  NB = 'nb',
  DA = 'da',
  FI = 'fi',
}

@Injectable()
export class HyggloTranslateService {
  private currentLanguage: LANGUAGE;
  public onLangChange: Subject<LANGUAGE> = new Subject<LANGUAGE>();

  constructor(
    private ngRedux: NgRedux<any>,
    private translate: TranslateService,
    private config: AppConfigService,
    private titleService: Title
  ) {
    translate.addLangs(['sv', 'en', 'nb', 'da', 'fi']);

    const initialLang = this.getInitialLanguage();

    translate.onLangChange.subscribe(({ lang }) => {
      this.onLangChange.next(this.stringToLanguage(lang));
    });

    this.setLanguage(initialLang);

    localStorage.setItem('language', initialLang);

    ngRedux
      .select(['account', 'response', 'customer', 'language'])
      .pipe(filter(Boolean))
      .pipe(distinctUntilChanged())
      .pipe(filter((lang) => lang !== this.currentLanguage))
      .subscribe((lang: LANGUAGE) => {
        this.setLanguage(lang);
      });
  }

  private getInitialLanguage(): LANGUAGE {
    try {
      const language = localStorage.getItem('language');
      if (
        language === LANGUAGE.NB ||
        language === LANGUAGE.EN ||
        language === LANGUAGE.SV ||
        language === LANGUAGE.DA ||
        language === LANGUAGE.FI
      ) {
        return language;
      }
    } catch (err) {}
    if (this.config && this.config.config.defaultLang) {
      return this.stringToLanguage(this.config.config.defaultLang);
    } else {
      return LANGUAGE.EN;
    }
  }

  public stringToLanguage(language: string): LANGUAGE {
    switch (language) {
      case 'sv':
        return LANGUAGE.SV;
      case 'nb':
        return LANGUAGE.NB;
      case 'da':
        return LANGUAGE.DA;
      case 'fi':
        return LANGUAGE.FI;
      case 'en':
        return LANGUAGE.EN;
      default:
        return LANGUAGE.EN;
    }
  }

  public setLanguage(language: LANGUAGE) {
    this.translate.use(language);
    // Settings.defaultLocale = language;
    this.currentLanguage = language;
    localStorage.setItem('language', language);
    const userId: number = this.ngRedux.getState().getIn(['account', 'response', 'customer', 'id']);

    if (userId) {
      if (
        this.ngRedux.getState().getIn(['account', 'response', 'customer', 'language']) !== language
      ) {
        myUserAPI.setLanguage(userId, language);
      }
    }
  }

  public getLanguage(): LANGUAGE {
    return this.currentLanguage;
  }

  public async setTitle(key: string) {
    const title = await this.translate.get(key).toPromise();
    this.titleService.setTitle(title);
  }

  public instant(key: string, opts?: Object) {
    return this.translate.instant(key, opts);
  }

  public openFaq(faqArticle?: CrispArticleType) {
    const articleId = faqArticle ? articleList[faqArticle][this.currentLanguage] : null;
    const subUrl = articleId ? `article/${articleId}` : '';
    const url = `https://faq.hygglo.info/${
      this.currentLanguage === 'nb' ? 'no' : this.currentLanguage
    }/${subUrl}`;
    console.log(url);
    window.open(url);
  }

  public currencySymbolForCountry(countryCode: string): string {
    return (
      { SE: 'kr', NO: 'kr', FI: '€', DK: 'kr' }[countryCode] || `[no currency for '${countryCode}']`
    );
  }

  public ISOcurrencySymbolForCountry(countryCode: string): string {
    return (
      { SE: 'SEK', NO: 'NOK', FI: 'EUR', DK: 'DKK' }[countryCode] ||
      `[no ISO currency for '${countryCode}']`
    );
  }
}
