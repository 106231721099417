/// <reference path='../../node_modules/immutable/dist/immutable.d.ts'/>

import * as typeGenerator from '../types/typeGenerator';
import { fromJS, Map } from 'immutable';

const initialState = fromJS({});

const successReducer = (state, action) => {
  const list = action.data.get('response');
  return Map({
    list
  });
};

const topCategories = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case typeGenerator.loading('topCategories'):
    case typeGenerator.error('topCategories'):
      const status = action.data.get('status');
      return state.setIn(['status'], status);
    case typeGenerator.success('topCategories'):
      state.setIn(['status'], action.data.get('status'));
      return successReducer(state, action);
    default:
      return state;
  }
};

export default topCategories;
