import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { Angulartics2 } from 'angulartics2';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert/alert.service';

// Events
import eventTypes from '../../types/eventTypes';
import EventEmitter from '../../utils/EventEmitter';

export interface Location {
  country?: string;
  municipality?: string;
  name?: string;
  latitude?: number;
  longitude?: number;
  verified?: boolean;
}

const ee = new EventEmitter();

@Injectable()
export class LocationService {
  private location: Location;
  private $location = new Subject<Location>();
  private dialogRef: any;
  public verifyDialogIsOpen = false;

  constructor(
    private angulartics2: Angulartics2,
    public dialogService: MatDialog,
    public alertService: AlertService,
    public translate: TranslateService,
    public router: Router
  ) {
    this.location = {
      municipality: undefined,
      name: undefined,
      latitude: undefined,
      longitude: undefined,
      verified: false,
    };

    const savedLocation = localStorage.getItem('currentPosition');
    let location = undefined;
    try {
      location = JSON.parse(savedLocation);
    } catch (error) {}

    if (location && location.latitude && location.longitude) {
      // make sure we're compatible with legacy
      this.setLocation({
        ...location,
        name: location.name || location.address,
        address: undefined,
      });
    } else {
      setTimeout(() => {
        this.getLocationByIP();
      }, 5);
    }

    ee.events.on(eventTypes.LOCATION_CHANGED, (result) => {
      this.setLocation(result);
    });
  }

  public getLocation(): Location {
    return this.location;
  }

  public isInHyggloZone(): boolean {
    const hyggloCountries = ['SE', 'NO', 'DK', 'FI'];
    return this.location.country && hyggloCountries.includes(this.location.country);
  }

  public setLocation(newLocation: Location, verified = true, sendUpdate = true) {
    const location = { ...newLocation, ...{ verified } };
    if (newLocation.country) {
      localStorage.setItem('country', newLocation.country);
    }
    localStorage.setItem('currentPosition', JSON.stringify(location));
    this.location = location;
    if (sendUpdate) {
      this.$location.next(<Location>location);
    }
  }

  public onLocationChange(): Observable<Location> {
    return this.$location;
  }

  public openDialog(context?) {
    const data = { context, currentPosition: this.getLocation() };
    this.router.navigate([{ outlets: { modal: ['myproducts', 'select-location'] } }], {
      state: data,
      skipLocationChange: true,
    });
  }

  public async verifyLocation(context?, parent?: any) {
    if (this.verifyDialogIsOpen === false && this.isInHyggloZone()) {
      this.verifyDialogIsOpen = true;
      const imgString = `<img src="https://maps.googleapis.com/maps/api/staticmap?markers=size:tiny|color:red|${this.location.latitude},${this.location.longitude}&zoom=11&size=200x200&scale=2&key=AIzaSyB6NZw8fBmb-K6T4aT_XlZy2jIVPqrjmOQ"
    />`;

      this.translate
        .get([
          'LOCATION_SELECTOR.VERIFY.TITLE',
          'LOCATION_SELECTOR.VERIFY.INTRO',
          'LOCATION_SELECTOR.VERIFY.LOCATION',
        ])
        .subscribe((res: Object) => {
          this.alertService
            .confirm(
              imgString +
                `<br><br>${res['LOCATION_SELECTOR.VERIFY.INTRO']}<br><br>${res['LOCATION_SELECTOR.VERIFY.LOCATION']}<br><b>` +
                this.location.name +
                '</b>?',
              res['LOCATION_SELECTOR.VERIFY.TITLE']
            )
            .then(async (result: string) => {
              this.verifyDialogIsOpen = false;
              if (result === 'NO') {
                return await this.openDialog(context);
              } else {
                this.setLocation(this.location, true, false);
                return true;
              }
            })
            .catch(async (e) => {
              this.verifyDialogIsOpen = false;
            });
        });
    }
  }

  private async getLocationByIP() {
    const isOnQueryLocationPage = window.location.href.match(/\/category\/[^\/]+\/./); // HACK: Don't do ip lookups when we get location area from url
    if (!this.location.latitude && !isOnQueryLocationPage) {
      const res = await fetch('https://ipinfo.io/geo?token=a41c684dd7af57', {});
      const body = await res.json();

      if (/2\d{2}/.test(String(res.status))) {
        const lat = Number(body.loc.substr(0, body.loc.indexOf(',')));
        const lng = Number(body.loc.substr(body.loc.indexOf(',') + 1));

        this.setLocation(
          {
            country: body.country,
            municipality: '',
            name: body.city,
            latitude: lat,
            longitude: lng,
          },
          false
        );
        if (this.dialogRef) {
          this.dialogRef.close();
        }

        this.angulartics2.eventTrack.next({
          action: 'Place automatically updated based on IP',
          properties: {
            label: '',
            category: 'Other',
          },
        });
      } else {
      }
    }
  }

  public testIfMobile(): boolean {
    return window.innerWidth < 700;
  }
}
