import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { fromJS } from 'immutable';
// import { init, captureException } from '@sentry/browser';
import { environment } from '../environments/environment';
import rootReducer from '../reducers/rootReducer';
import { HyggloCommonModule } from './modules/hygglo-common.module';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';

// components
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

// Page components
import { AppRoutingModule } from './router';
// Services
import { DateService } from './services/date-selector.service';
import { LocationService } from './services/location.service';
import { DeviceIdService } from './services/device-id.service';
import { SocketService } from './services/socket.service';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { AppConfigService } from './services/app-config.service';
import { HyggloTranslateService } from './services/translate.service';
import { Angulartics2HyggloTracking } from './services/event.tracking';

// Language
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { MaterialModule } from './modules/material/material.module';
import { HyggloMissingTranslationHandler } from '../utils/HyggloMissingTranslationHandler';
import { from, Observable } from 'rxjs';
import { WebCategoryService } from './services/web-category.service';
export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.json`));
  }
}

// declare const window;
// declare var process: Process;
// interface Process {
//   env: ENV;
// }
// interface ENV {
//   SOURCE_VERSION: string;
// }

// Other
// if (environment.production) {
//   init({
//     dsn: 'https://7048b6961510438bbfdc4eb11a18f8a9@sentry.io/1206982',
//     environment: environment.environment
//     // release:
//     //   process && process.env && process.env.SOURCE_VERSION
//     //     ? process.env.SOURCE_VERSION
//     //     : 'development'
//   });
// }

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

// export class SentryErrorHandler implements ErrorHandler {
//   handleError(err: any): void {
//     // need console.log here so we can debug errors locally, uncaught errors are otherwise just
//     // silenty logged to sentry
//     console.log(err);
//     captureException(err);
//   }
// }

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    // NPSModalComponent,
  ],
  imports: [
    HyggloCommonModule,
    BrowserModule,
    NoopAnimationsModule,
    MaterialModule,
    FormsModule,
    AppRoutingModule,
    NgReduxModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: HyggloMissingTranslationHandler,
      },
      useDefaultLang: false, // Do not fallback to default language if translation is missing
    }),
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
    Title,
    DateService,
    DeviceIdService,
    SocketService,
    WebCategoryService,
    AppConfigService,
    HyggloTranslateService,
    Angulartics2HyggloTracking,
    LocationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService],
    },
    // environment.production ? [{ provide: ErrorHandler, useClass: SentryErrorHandler }] : []
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<any>) {
    const middleware = [];
    const enhancers = [];
    if ((<any>window).__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push((<any>window).__REDUX_DEVTOOLS_EXTENSION__());
    }
    ngRedux.configureStore(rootReducer, fromJS({}), middleware, enhancers);
  }
}
