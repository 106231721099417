import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

import { fetchPersistentStorage } from './fetchPersistentStorage';

@Injectable()
export class StorageActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchPersistentStorage = fetchPersistentStorage(this.ngRedux.dispatch);
}
