import { LANGUAGE } from './translate.service';

export enum CrispArticleType {
  INSURANCE_OWNER = 'insurance_owner',
  INSURANCE_RENTER = 'insurance_renter',
  HOW_IT_WORKS_IN_SHORT = 'how_it_works_in_short',
  VALUATION = 'valuation',
  CANCELLATION = 'cancellation',
  PAYOUTS = 'payouts',
  VP_VERIFIED = 'vp_verified',
  VP_CUSTOMER_SUPPORT = 'vp_customer_support',
  VP_FAST_REPLIES = 'vp_fast_replies',
  VP_EARNINGS = 'vp_earnings',
  VP_CHEAP = 'vp_cheap',
  VP_CLOSE_TO_YOU = 'vp_close_to_you',
  VP_TIME_THAT_SUITS_YOU = 'vp_time_that_suits_you',
  VP_GOOD_FOR_ENVIRONMENT = 'vp_good_for_environment',
  VP_COST_OF_USAGE = 'vp_cost_of_usage',
  VP_NO_RESTRICTIONS = 'vp_no_restrictions',
}

type ArticleLanguageCombination = Record<CrispArticleType, Partial<Record<LANGUAGE, string>>>;

export const articleList: ArticleLanguageCombination = {
  [CrispArticleType.INSURANCE_OWNER]: {
    [LANGUAGE.DA]: 'x74xud',
    [LANGUAGE.EN]: 'huw4sy',
    [LANGUAGE.FI]: '10xlkme',
    [LANGUAGE.NB]: 'a3ml9a',
    [LANGUAGE.SV]: 'w9d80i',
  },
  [CrispArticleType.INSURANCE_RENTER]: {
    [LANGUAGE.DA]: '1jhdwau',
    [LANGUAGE.EN]: '1umvdun',
    [LANGUAGE.FI]: '1rmbj0f',
    [LANGUAGE.NB]: 'un2irj',
    [LANGUAGE.SV]: '14mf4f7',
  },
  [CrispArticleType.HOW_IT_WORKS_IN_SHORT]: {
    [LANGUAGE.DA]: '1ew44t3',
    [LANGUAGE.EN]: 'deojp3',
    [LANGUAGE.FI]: '1bqls3c',
    [LANGUAGE.NB]: 'f3kp7b',
    [LANGUAGE.SV]: 'i4s8nq',
  },
  [CrispArticleType.VALUATION]: {
    [LANGUAGE.DA]: '17mxuw8',
    [LANGUAGE.EN]: '1wwtyd0',
    [LANGUAGE.FI]: 'xlzpel',
    [LANGUAGE.NB]: '12lsquk',
    [LANGUAGE.SV]: '1yztd4m',
  },
  [CrispArticleType.CANCELLATION]: {
    [LANGUAGE.DA]: '1euagv9',
    [LANGUAGE.EN]: '1ltm70t',
    [LANGUAGE.FI]: '15rq6f2',
    [LANGUAGE.NB]: 'tdie56',
    [LANGUAGE.SV]: '14k57bh',
  },
  [CrispArticleType.PAYOUTS]: {
    [LANGUAGE.DA]: 'zdk437',
    [LANGUAGE.EN]: '3dgvk4',
    [LANGUAGE.FI]: '1uvp47b',
    [LANGUAGE.NB]: '43879h',
    [LANGUAGE.SV]: 'jifnz7',
  },
  [CrispArticleType.VP_VERIFIED]: {
    [LANGUAGE.DA]: '7t9eq1',
    [LANGUAGE.EN]: 'zgg9y7',
    [LANGUAGE.FI]: '1axk290',
    [LANGUAGE.NB]: '58exio',
    [LANGUAGE.SV]: '1ud4u3w',
  },
  [CrispArticleType.VP_CUSTOMER_SUPPORT]: {
    [LANGUAGE.DA]: '1kfk9xm',
    [LANGUAGE.EN]: '1ve5ei9',
    [LANGUAGE.FI]: 'pusjxw',
    [LANGUAGE.NB]: '1g3fbzr',
    [LANGUAGE.SV]: '1im1s58/',
  },
  [CrispArticleType.VP_FAST_REPLIES]: {
    [LANGUAGE.DA]: 'eblhko',
    [LANGUAGE.EN]: '727ode',
    [LANGUAGE.FI]: '1x272n1',
    [LANGUAGE.NB]: '1f9jg1m',
    [LANGUAGE.SV]: '149qgrh',
  },
  [CrispArticleType.VP_EARNINGS]: {
    [LANGUAGE.DA]: 'oj0zv9',
    [LANGUAGE.EN]: 'coyz3j',
    [LANGUAGE.FI]: 'iph966',
    [LANGUAGE.NB]: '1m2mnli',
    [LANGUAGE.SV]: 'ry8jlu',
  },
  [CrispArticleType.VP_CHEAP]: {
    [LANGUAGE.DA]: '1m5146j',
    [LANGUAGE.EN]: '1gf6zea',
    [LANGUAGE.FI]: 'm104b9',
    [LANGUAGE.NB]: 'bie0o8',
    [LANGUAGE.SV]: '15m25ur',
  },
  [CrispArticleType.VP_CLOSE_TO_YOU]: {
    [LANGUAGE.DA]: '1nnbbif',
    [LANGUAGE.EN]: '30zjc9',
    [LANGUAGE.FI]: '17prh05',
    [LANGUAGE.NB]: 'l930wr',
    [LANGUAGE.SV]: '1wg4f1i',
  },
  [CrispArticleType.VP_TIME_THAT_SUITS_YOU]: {
    [LANGUAGE.DA]: '1jg9673',
    [LANGUAGE.EN]: '1c6w2ac',
    [LANGUAGE.FI]: 'qgeqsw',
    [LANGUAGE.NB]: 'kqzasz',
    [LANGUAGE.SV]: '1jhs2s6',
  },
  [CrispArticleType.VP_GOOD_FOR_ENVIRONMENT]: {
    [LANGUAGE.DA]: '1oldt5r',
    [LANGUAGE.EN]: 'o04ui2',
    [LANGUAGE.FI]: '1pio0pa',
    [LANGUAGE.NB]: 'jl2c4a',
    [LANGUAGE.SV]: '1h1bq9p',
  },
  [CrispArticleType.VP_COST_OF_USAGE]: {
    [LANGUAGE.DA]: 'cl46e9',
    [LANGUAGE.EN]: 'l106i4',
    [LANGUAGE.FI]: '18hwg2y',
    [LANGUAGE.NB]: '5dq758',
    [LANGUAGE.SV]: '1qg4wsq',
  },
  [CrispArticleType.VP_NO_RESTRICTIONS]: {
    [LANGUAGE.DA]: '1v2i3as',
    [LANGUAGE.EN]: '47npkt',
    [LANGUAGE.FI]: 'xz5ofi',
    [LANGUAGE.NB]: 'd4zy8d',
    [LANGUAGE.SV]: '169ti4w',
  },
};

export type ArticleTypeValue = typeof CrispArticleType[keyof typeof CrispArticleType];
