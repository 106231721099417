import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const factsAPI = {
  async fetchFactBySlug(slug: string) {
    const uri = buildUrl(`/v2/fact/${slug}`);
    const options = {
      uri,
      responseType: 'json'
    };
    const { body } = await request(options, true);
    return body;
  }
};
