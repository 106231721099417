import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { Dispatch } from 'redux';

export const clearSearchResult = (dispatch: Dispatch<any>) => () => {
  return dispatch({
    data: fromJS({}),
    type: actionTypes.SEARCH_CLEAR
  });
};
