import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { Dispatch } from 'redux';

const clearDataState = () => {
  return {
    data: fromJS({}),
    type: actionTypes.CLEAR_NOTIFICATIONS_STATE
  };
};

export const clearNotificationsState = (dispatch: Dispatch<any>) => {
  return () => {
    dispatch(clearDataState());
  };
};
