import {
  Component,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DateService } from '../../services/date-selector.service';

@Component({
  selector: 'date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateSelectorComponent {
  public dateString: string;

  constructor(private dateService: DateService, private cdr: ChangeDetectorRef) {
    this.setDateStrings();

    this.dateService.onDateRangeChange().subscribe((dateRange) => {
      this.setDateStrings();
      this.cdr.detectChanges();
    });
  }

  setDateStrings() {
    this.dateString = this.dateService.toString();
  }

  public selectDates() {
    this.dateService.openDialog();
  }
}
