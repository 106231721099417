import actionTypes from '../types/actionTypes';
import { fromJS } from 'immutable';
const initialState = fromJS({});

const bootstrap = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'bootstrap']) || fromJS({});
    case actionTypes.BOOTSTRAP_FETCH_INIT:
    case actionTypes.BOOTSTRAP_FETCH_FAIL:
      const status = action.data.get('status');
      return state.setIn(['status'], status);
    case actionTypes.BOOTSTRAP_FETCH_SUCCESS:
      return action.data;

    case actionTypes.PRODUCT_SET_FAVORITE:
      const listingId = parseInt(action.data.get('id'), 10);
      const isFavorite = action.data.get('isFavorite');

      const latestOrderProductListings = state.getIn(['response', 'latestOrderProductListings']);

      let newState = state.setIn(
        ['response', 'latestOrderProductListings'],
        latestOrderProductListings
          ? latestOrderProductListings.map((listing) =>
              listing.get('id') === listingId ? listing.set('isFavorite', isFavorite) : listing
            )
          : null
      );

      return newState;

    default:
      return state;
  }
};

export default bootstrap;
