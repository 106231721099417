/* Modules */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

// Language
import { TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { HyggloMissingTranslationHandler } from '../../utils/HyggloMissingTranslationHandler';

/* Others */
import { Rating } from './../components/rating/rating.component';
import { FancyImg } from './../components/fancyImg/fancy.img.component';
import { AutocompleteComponent } from './../components/autocomplete/autocomplete.component';
import { ClickDebounceDirective } from './../directives/click-debounce/click-debounce.directive';
import { BreadcrumbsComponent } from '../components/breadcrumbs/breadcrumbs.component';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { ProductReviewsComponent } from '../components/product-reviews/product-reviews.component';
import { ProductCardComponent } from '../components/productCard/product.card.component';
import { HyggloInfoComponent } from '../components/hygglo-info/hygglo-info.component';
import { LocationSelectorComponent } from '../components/location-selector/location-selector.component';
// import { DateSelectorModalComponent } from '../components/date-selector-modal/date-selector-modal.component';
import { DateSelectorComponent } from '../components/date-selector/date-selector.component';
import { ProductListComponent } from '../components/product-list/product-list.component';
import { ProductReviewCardComponent } from '../components/product-review-card/product-review-card.component';
import { PressContainerComponent } from '../components/press-container/press-container.component';
import { TestimonialsComponent } from '../components/testimonials/testimonials.component';
import { SearchComponent } from '../components/search/search.component';
import { DialogComponent } from '../services/alert/dialog/dialog.component';
import { AlertService } from '../services/alert/alert.service';

// Directives
import { LazyloadDirective } from '../directives/lazyload.directive';
import { RouterLinkDirective } from '../directives/router-link.directive';
import { HyggloJsonLdDirective } from '../directives/hygglo-json-ld';

import { FeatureFlags } from '../feature-flags';

// Actions
import { AccountActions } from '../../actions/account';
import { BootstrapActions } from '../../actions/bootstrap';
import { CategoriesActions } from '../../actions/categories';
import { NewProductActions } from '../../actions/newProduct';
import { OrderActions } from '../../actions/order';
import { NotificationsActions } from '../../actions/notifications';
import { StorageActions } from '../../actions/storage';
import { ProductActions } from '../../actions/product';
import { ReviewsActions } from '../../actions/reviews';
import { SearchActions } from '../../actions/search';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule.forChild({
      extend: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: HyggloMissingTranslationHandler
      },
      useDefaultLang: false // Do not fallback to default language if translation is missing
    })
  ],
  declarations: [
    Rating,
    FancyImg,
    AutocompleteComponent,
    ClickDebounceDirective,
    BreadcrumbsComponent,
    NotFoundComponent,
    ProductReviewsComponent,
    ProductCardComponent,
    HyggloInfoComponent,
    LocationSelectorComponent,
    DateSelectorComponent,
    ProductListComponent,
    ProductReviewCardComponent,
    PressContainerComponent,
    TestimonialsComponent,
    SearchComponent,
    DialogComponent,
    // Directives
    LazyloadDirective,
    RouterLinkDirective,
    HyggloJsonLdDirective
  ],
  providers: [
    FeatureFlags,
    // Actions
    AccountActions,
    BootstrapActions,
    CategoriesActions,
    NewProductActions,
    NotificationsActions,
    OrderActions,
    ProductActions,
    ReviewsActions,
    SearchActions,
    StorageActions,
    AlertService
  ],
  exports: [
    Rating,
    FancyImg,
    AutocompleteComponent,
    BreadcrumbsComponent,
    NotFoundComponent,
    ProductReviewsComponent,
    ProductCardComponent,
    HyggloInfoComponent,
    LocationSelectorComponent,
    DateSelectorComponent,
    ProductListComponent,
    ProductReviewCardComponent,
    PressContainerComponent,
    TestimonialsComponent,
    SearchComponent,
    RouterLinkDirective,
    ClickDebounceDirective,
    HyggloJsonLdDirective
  ]
})
export class HyggloCommonModule {}
