export const AlertType = {
  ALERT: 'ALERT',
  CONFIRM: 'CONFIRM'
};

export const ResultType = {
  OK: 'OK',
  YES: 'YES',
  NO: 'NO',
  CANCEL: 'CANCEL'
};
