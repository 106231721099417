import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HyggloTranslateService } from '../../translate.service';

import { AlertType, ResultType } from '../alert.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  public alertType = AlertType;
  public resultType = ResultType;

  public type: string;
  public title: string;
  public message: string;
  public input: { placeholder?: string; type?: string; required?: boolean } = {};
  public buttons: { ok?: string; yes?: string; no?: string } = {};

  public value: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    private translate: HyggloTranslateService
  ) {
    this.dialogRef = dialogRef;

    this.type = dialogData.type;
    this.message = dialogData.message;
    this.title = dialogData.title;
    this.input.placeholder = dialogData.input?.placeholder ?? '';
    this.input.type = dialogData.input?.type ?? null;
    this.input.required = dialogData.input?.required ?? true;
    this.buttons = dialogData.buttons;
  }

  public dismissDialog(result?: string) {
    if (!this.input.type) {
      return this.dialogRef.close(result);
    }

    if (result == 'CANCEL' && !this.input.required) {
      return this.dialogRef.close();
    }

    if (!this.value) {
      return alert(this.translate.instant('DIALOG.INPUT.EMPTY'));
    }

    if (this.input.type == 'number') {
      const isValidNumber = !isNaN(+this.value);
      if (!isValidNumber) {
        return alert(this.translate.instant('DIALOG.INPUT.NUMBER'));
      }

      this.dialogRef.close(+this.value);
    }
  }
}
