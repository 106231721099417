import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { reviewsAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const fetchReviews = (dispatch: Dispatch<any>) => async (userId: number) => {
  const setSuccessState = (response: any) => {
    return {
      data: fromJS({
        userId,
        response: response
      }),
      type: actionTypes.FETCH_REVIEWS
    };
  };

  const response = await reviewsAPI.fetchReviewsByUserId(userId);

  return dispatch(setSuccessState(response));
};

export const fetchRatingsReviews = (dispatch: Dispatch<any>) => async () => {
  const setSuccessState = (response: any) => {
    return {
      data: fromJS({
        response: response
      }),
      type: actionTypes.FETCH_RATINGS_REVIEWS
    };
  };

  const response = await reviewsAPI.getLastTopReviews();

  return dispatch(setSuccessState(response));
};
