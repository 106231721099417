import request from '../../utils/request';

export const fetchAll = async (uri: string, silent: boolean) => {
  const options = {
    uri,
    responseType: 'json',
  };

  const response: any = await request(options, silent)

  return response.body;
}
