import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { productAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const setAsFavorite = (dispatch: Dispatch<any>) => async (id: string) => {
  const setSuccessState = () => {
    return {
      data: fromJS({
        id,
        isFavorite: true
      }),
      type: actionTypes.PRODUCT_SET_FAVORITE
    };
  };
  const response = await productAPI.setAsFavorite(id);
  dispatch(setSuccessState());
  return response;
};
