import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const usersAPI = {
  async fetchUser(userId: string, silent?: boolean) {
    const options = {
      responseType: 'json',
      uri: buildUrl(`/v2/users/${userId}`)
    };
    const response = await request(options, silent);
    return response.body;
  }
};
