import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const newProduct = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'newProduct']) || fromJS({});
    case actionTypes.NEWPRODUCT_UPDATE_INFO:
      return state.merge(action.data);
    case actionTypes.NEWPRODUCT_ADD_IMAGE:
      const id = action.data.getIn(['id']);
      const imgUrl = action.data.getIn(['imgUrl']);
      return state.setIn(['productPictures', id.toString()], imgUrl);
    case actionTypes.NEWPRODUCT_DELETE_IMAGE:
      return state.deleteIn(['productPictures', action.data.getIn(['id']).toString()]);
    case actionTypes.CLEAR_STATE:
    case actionTypes.CLEAR_NEW_PRODUCT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default newProduct;
