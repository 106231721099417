import {
  Input,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { LocationService, Location } from '../../services/location.service';

@Component({
  selector: 'location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationSelectorComponent implements OnDestroy {
  @Input()
  public isInHeader: boolean;

  public currentPosition: Location;
  private locationChangeSubscription: any;

  constructor(private locationService: LocationService, private cdr: ChangeDetectorRef) {
    this.currentPosition = this.locationService.getLocation();
    this.locationChangeSubscription = this.locationService
      .onLocationChange()
      .subscribe((location: Location) => {
        this.currentPosition = location;
        this.cdr.detectChanges();
      });
  }

  public openDialog(context?) {
    this.locationService.openDialog(context);
  }

  public ngOnDestroy(): void {
    if (this.locationChangeSubscription) {
      this.locationChangeSubscription.unsubscribe();
    }
  }
}
