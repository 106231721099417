import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const paidOutOrders = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.PAID_OUT_ORDERS_FETCH_INIT:
    case actionTypes.PAID_OUT_ORDERS_FETCH_SUCCESS:
    case actionTypes.PAID_OUT_ORDERS_FETCH_FAIL:
      return action.data;

    default:
      return state;
  }
};

export default paidOutOrders;
