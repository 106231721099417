const eventTypes = {
  BANKID_INITIATED: 'BANKID_INITIATED',
  BANKID_CLOSE: 'BANKID_CLOSE',

  ACCOUNT_LOGIN_FAIL: 'ACCOUNT_LOGIN_FAIL',
  ACCOUNT_LOGIN_REQUESTED: 'ACCOUNT_LOGIN_REQUESTED',
  ACCOUNT_LOGIN_CLOSED: 'ACCOUNT_LOGIN_CLOSED',
  ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',
  EMAIL_LOGIN_FAIL: 'EMAIL_LOGIN_FAIL',
  /* User initiated events */
  USER_LOGIN_ABORT: 'USER_LOGIN_ABORT',
  USER_LOGIN_TIMEOUT: 'USER_LOGIN_TIMEOUT',
  USER_REGISTER_ABORT: 'USER_REGISTER_ABORT',
  BANKID_ALREADY_IN_PROGRESS: 'BANKID_ALREADY_IN_PROGRESS',

  BANKID_USER_IN_APP: 'BANKID_USER_IN_APP',
  BANKD_AUTH_TOKEN_RECEIVED: 'BANKD_AUTH_TOKEN_RECEIVED',

  ACCOUNT_REGISTER_REQUESTED: 'ACCOUNT_REGISTER_REQUESTED',
  ACCOUNT_REGISTER_FINISHED: 'ACCOUNT_REGISTER_FINISHED',

  SIGN_FAIL: 'SIGN_FAIL',
  SIGN_REQUESTED: 'SIGN_REQUESTED',
  SIGN_SUCCESS: 'SIGN_SUCCESS',

  APP_BUSY: 'APP_BUSY',
  APP_NOT_BUSY: 'APP_NOT_BUSY',

  GENERIC_ERROR: 'GENERIC_ERROR',
  LOCATION_CHANGED: 'LOCATION_CHANGED',
  LOGOUT: 'LOGOUT',

  REFRESH_ORDERS_LIST: 'REFRESH_ORDERS_LIST',
  NEWEST_MESSAGE_SHOWN: 'NEWEST_MESSAGE_SHOWN',
};

export default eventTypes;
