import { fetchReviews, fetchRatingsReviews } from './fetchReviews';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class ReviewsActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchReviews = fetchReviews(this.ngRedux.dispatch);

  fetchRatingsReviews = fetchRatingsReviews(this.ngRedux.dispatch);
}
