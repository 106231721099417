import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const myProducts = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'myProducts']) || fromJS({});
    case actionTypes.MY_PRODUCTS_FETCH_INIT:
    case actionTypes.MY_PRODUCTS_FETCH_FAIL:
      return state.set('status', action.data.get('status'));
    case actionTypes.MY_PRODUCTS_FETCH_SUCCESS:
      return action.data;
    case actionTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default myProducts;
