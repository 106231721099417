<h1 matDialogTitle>{{ this.title }}</h1>

<mat-dialog-content innerHTML="{{ this.message }}"></mat-dialog-content>

<mat-dialog-actions>
  <ng-container *ngIf="type === alertType.ALERT">
    <input *ngIf="this.input.type" [placeholder]="this.input.placeholder" [(ngModel)]="this.value" name="alert-input" />
    <button mat-raised-button (click)="dismissDialog(resultType.OK)" color="accent">
      {{ buttons?.ok || ('DIALOG.OK' | translate) }}
    </button>
  </ng-container>

  <ng-container *ngIf="type === alertType.CONFIRM">
    <button mat-raised-button (click)="dismissDialog(resultType.YES)" color="accent">
      {{ buttons?.yes || ('DIALOG.YES' | translate) }}
    </button>
    <button mat-raised-button (click)="dismissDialog(resultType.NO)">
      {{ buttons?.no || ('DIALOG.NO' | translate) }}
    </button>
  </ng-container>

  <button (click)="dismissDialog(resultType.CANCEL)" class="matDialogClose-button"><mat-icon>clear</mat-icon></button>
</mat-dialog-actions>
