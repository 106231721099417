declare let localStorage: any;

const wrap = <T>(fn: () => T): Promise<T> => {
  try {
    return Promise.resolve(fn());
  } catch (e) {
    return Promise.reject(e);
  }
};

const storage = {
  get(key: string): Promise<string> {
    return wrap((): string => localStorage.getItem(key));
  },

  set(key: string, data: any): Promise<void> {
    return wrap((): void => localStorage.setItem(key, data));
  },

  remove(key: string): Promise<void> {
    return wrap((): void => localStorage.removeItem(key));
  },

  clear(): Promise<void> {
    return wrap((): void => localStorage.clear());
  },

  isAvailable(): boolean {
    var mod = 'test';
    try {
      localStorage.setItem(mod, mod);
      localStorage.removeItem(mod);
      return true;
    } catch (e) {
      return false;
    }
  }
};

export default storage;
