import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const paymentsAPI = {
  async payOrder(orderId: number, paymentMethodId?: string) {
    const uri = buildUrl('/v2/my/payments');

    const { body } = await request({
      json: {
        orderId,
        paymentMethodId
      },
      uri,
      method: 'POST',
      intent: 'post stripe token'
    });

    return body;
  },

  async confirmOrder(orderId: number, paymentMethodId: string, paymentIntentId: string) {
    const uri = buildUrl('/v2/my/payments');

    const { body } = await request({
      json: {
        orderId,
        paymentMethodId,
        paymentIntentId
      },
      uri,
      method: 'POST',
      intent: 'confirm stripe token'
    });

    return body;
  },

  async fetchSavedCard() {
    const uri = buildUrl('/v2/my/stored-cards');

    const { body } = await request({
      uri,
      responseType: 'json'
    });

    return body[0];
  }
};
