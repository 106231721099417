import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

import { loginAccount } from './loginAccount';
import { updateAccount } from './updateAccount';
import { refreshAccount } from './refreshAccount';

@Injectable()
export class AccountActions {
  constructor(private ngRedux: NgRedux<any>) {}

  loginAccount = loginAccount(this.ngRedux.dispatch);

  updateAccount(data: any) {
    this.ngRedux.dispatch(updateAccount(data));
  }

  refreshAccount = refreshAccount(this.ngRedux.dispatch);
}
