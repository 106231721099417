import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { publicProductAPI } from '../../webapi/public/index';
import { Dispatch } from 'redux';

export const fetchProduct = (dispatch: Dispatch<any>) => async (id: string, silent?: boolean) => {
  const setLoadingState = () => {
    return {
      data: fromJS({
        id,
        status: 'LOADING'
      }),
      type: actionTypes.PRODUCT_FETCH_INIT
    };
  };

  const setErrorState = (error) => {
    return {
      data: fromJS({
        id,
        error,
        status: 'ERROR'
      }),
      type: actionTypes.PRODUCT_FETCH_FAIL
    };
  };

  const setSuccessState = (response) => {
    return {
      data: fromJS({
        id,
        response: response,
        status: 'SUCCESS'
      }),
      type: actionTypes.PRODUCT_FETCH_SUCCESS
    };
  };

  dispatch(setLoadingState());
  try {
    const response = await publicProductAPI.fetchProduct(id, silent);
    dispatch(setSuccessState(response));
    return response;
  } catch (err) {
    dispatch(setErrorState(err));
    return err;
  }
};
