import getAPIURI, { buildUrl } from '../webapi/getAPIURI';
import getAPIConfig from '../webapi/getAPIConfig';
import storage from './storage';
import EventEmitter from './EventEmitter';
import eventTypes from '../types/eventTypes';
import addHeaders from './common-headers';
import getTokens from './getTokens';
import get from 'lodash-es/get';

const formatForm = (obj: { [key: string]: string }) => {
  const params = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    params.append(key, obj[key]);
  });

  return params;
};

const ee = new EventEmitter();

/* eslint-disable no-console */
const appBusy = (isBusy: boolean, isSilent: boolean): void => {
  if (isBusy && !isSilent) {
    ee.events.emit(eventTypes.APP_BUSY);
  }
  if (!isBusy && !isSilent) {
    ee.events.emit(eventTypes.APP_NOT_BUSY);
  }
};

/* eslint-disable no-console */
const login = (intent: string) => {
  return new Promise(
    (resolve, reject): void => {
      let loginSuccess;
      let loginFail;

      loginFail = (data) => {
        ee.events.off(eventTypes.ACCOUNT_LOGIN_SUCCESS, loginSuccess);
        ee.events.off(eventTypes.ACCOUNT_LOGIN_FAIL, loginFail);
        reject(data);
      };

      loginSuccess = (data) => {
        ee.events.off(eventTypes.ACCOUNT_LOGIN_SUCCESS, loginSuccess);
        ee.events.off(eventTypes.ACCOUNT_LOGIN_FAIL, loginFail);

        resolve(data);
      };

      ee.events.on(eventTypes.ACCOUNT_LOGIN_SUCCESS, loginSuccess);
      ee.events.on(eventTypes.ACCOUNT_LOGIN_FAIL, loginFail);
      ee.events.emit(eventTypes.ACCOUNT_LOGIN_REQUESTED, intent);
    }
  );
};

/* eslint-disable no-console */
const refreshAccessToken = async (tokens, silent) => {
  const clientSecret = getAPIConfig.clientSecret();
  const clientId = getAPIConfig.clientId();
  const url = getAPIURI.token();
  const refreshToken = tokens.refresh_token;

  const options = {
    body: formatForm({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: clientId,
      client_secret: clientSecret
    }),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'POST'
  };

  appBusy(true, silent);

  addHeaders(options);
  const res = await fetch(url, options);
  const data = await res.text();
  const status = res.status;

  appBusy(false, silent);

  const json = JSON.parse(data);

  if (json && json.access_token) {
    try {
      await storage.set('tokens', JSON.stringify(json));
      return json;
    } catch (err) {
      // do nothing
    }
  } else {
    throw { status };
  }
};

export const getJson = ({ path, showSpinner = false }: { path: string; showSpinner?: boolean }) =>
  request({ uri: buildUrl(path), responseType: 'json' }, !showSpinner);

/* eslint-disable no-console */
const doInitialRequest = async (
  settings,
  tokens,
  silent
): Promise<{ body: Object; statusCode: number }> => {
  const options = { ...settings };

  const headers: { [header: string]: 'string' } = {
    ...(tokens && tokens.access_token && !settings.simple
      ? { Authorization: `Bearer ${tokens.access_token}` }
      : {}),
    'Content-Type': options.form
      ? 'application/x-www-form-urlencoded'
      : settings.simple
      ? 'text/plain'
      : 'application/json',
    ...(settings.simple ? {} : { 'User-Client': 'Hygglo-web' }),
    ...(options.headers || {})
  };

  appBusy(true, silent);

  options.headers = headers;
  addHeaders(options);

  const returnJson = !!options.json || options.responseType === 'json';

  /* Add country as url param instead of header (not not create preflight) */
  options.uri += `${options.uri.includes('?') ? '&' : '?'}country=${localStorage.getItem(
    'country'
  )}`;

  const res = await fetch(options.uri, {
    headers: options.headers,
    method: options.method,
    body: options.form
      ? formatForm(options.form)
      : options.json
      ? JSON.stringify(options.json)
      : options.body
  });

  let body = '';
  const { status } = res;
  try {
    if (returnJson) {
      body = await res.json();
    } else {
      body = await res.text();
    }
  } catch (error) {
    console.log(error);
  }

  appBusy(false, silent);

  if (/2\d{2}/.test(String(res.status))) {
    return { body, statusCode: status };
  } else {
    throw { status, tokens, response: { body, status } };
  }
};

export type JsonType = { [key: string]: any };

export interface RequestResponseType<T = JsonType> {
  body: T;
  statusCode: number;
}

/* eslint-disable no-console */
const request = (
  settings: any,
  silent = false,
  needsSign = false,
  isLogout = false
): Promise<{ body: any; statusCode: number }> => {
  // Get access and request tokens from storage.
  return new Promise((resolve, reject) => {
    const intent = settings && settings.intent ? settings.intent : '';
    const loginAndDoInitialRequest = async (loginIntent?: string) => {
      try {
        const tokens = await login(loginIntent || intent);
        const value = await doInitialRequest(settings, tokens, silent);
        return resolve(value);
      } catch (response) {
        reject(response || { status: 'No response' });
      }
    };
    const tokens = getTokens();
    doInitialRequest(settings, tokens, silent)
      .then(resolve)
      .catch(({ status, tokens, response }) => {
        if (status === 401 && get(response, 'body.error.code') === 'USER_MUST_BE_VERIFIED') {
          loginAndDoInitialRequest('VERIFY');
        } else if (status === 401 && !isLogout) {
          if (tokens) {
            refreshAccessToken(tokens, silent)
              .then((tokens2) => doInitialRequest(settings, tokens2, silent).then(resolve))
              .catch(({ status }) => {
                storage.remove('tokens').then(() => {
                  loginAndDoInitialRequest();
                });
              });
          } else {
            loginAndDoInitialRequest();
          }
        } else {
          reject(response);
        }
      });
  });
};

export default request;
