import { Injectable } from '@angular/core';

@Injectable()
export class FeatureFlags {
  constructor() {
    const parsedUrl = new URL(window.location.href);
    const _saved = localStorage.getItem('featureFlags');
    const saved = _saved ? JSON.parse(_saved) : {};
    let changed = false;
    const parseFeatureFlag = (name: string) => {
      const raw = parsedUrl.searchParams.get(`feature.${name}`);
      if (raw) {
        changed = true;
      }
      parsedUrl.searchParams.delete(`feature.${name}`);
      return raw ? (raw === '1' ? true : false) : saved[name];
    };

    // define flags on the class and then set it like this
    //  this.fooBar = parseFeaturFlag('fooBar');

    localStorage.setItem('featureFlags', JSON.stringify(this));

    if (changed) {
      window.location.replace(parsedUrl.toString());
    }
  }
}
