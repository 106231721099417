import { fetchNotifications, setNewNotificationsState } from './fetchNotifications';
import { consumeNotifications } from './consumeNotifications';
import { clearNotificationsState } from './clearNotificationsState';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class NotificationsActions {
  constructor(private ngRedux: NgRedux<any>) {}

  clearNotificationsState = clearNotificationsState(this.ngRedux.dispatch);

  consumeNotifications = consumeNotifications(this.ngRedux.dispatch);

  fetchNotifications = fetchNotifications(this.ngRedux.dispatch);

  setNewNotificationsState = setNewNotificationsState(this.ngRedux);
}
