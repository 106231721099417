import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import { AlertType, ResultType } from './alert.model';

@Injectable()
export class AlertService {
  static alertType = AlertType;
  static resultType = ResultType;

  constructor(public dialog: MatDialog) {}

  public async alert(
    message: string,
    title?: string,
    input?: { placeholder?: string | number; type: string; required?: boolean },
    buttons?: { ok?: string; yes?: string; no?: string }
  ): Promise<void | string | number> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '400px',
        data: {
          title,
          message,
          input,
          buttons,
          type: AlertService.alertType.ALERT,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }

  public confirm(message: string, title?: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '300px',
        data: {
          title,
          message,
          type: AlertService.alertType.CONFIRM,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        resolve(result);
      });
    });
  }
}
