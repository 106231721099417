import { fetchSearchResult } from './fetchSearchResult';
import { fetchMyProducts } from './fetchMyProducts';
import { fetchMyProduct } from './fetchMyProduct';
import { fetchMyFavorites } from './fetchMyFavorites';
import { clearSearchResult } from './clearSearchResult';

import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class SearchActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchSearchResult = fetchSearchResult(this.ngRedux.dispatch);

  fetchMyProduct = fetchMyProduct(this.ngRedux.dispatch);

  fetchMyProducts = fetchMyProducts(this.ngRedux.dispatch);

  fetchMyFavorites = fetchMyFavorites(this.ngRedux.dispatch);

  clearSearchResult = clearSearchResult(this.ngRedux.dispatch);
}
