<a
  (click)="openDialog()"
  class="{{ currentPosition && !currentPosition.name && !isInHeader ? 'has-no-location' : '' }}"
>
  <i></i>

  <span class="prefix" *ngIf="currentPosition && !!currentPosition.name"
    >{{ 'LOCATION_SELECTOR.CLOSE_TO' | translate }}
  </span>

  <span class="location-name">
    {{
      currentPosition && currentPosition.name ? currentPosition.name : ('LOCATION_SELECTOR.LOCATION_NAME' | translate)
    }} </span
  >&nbsp;

  <span class="cta">{{ 'LOCATION_SELECTOR.EDIT' | translate }}</span>
</a>
<p class="explanation" *ngIf="currentPosition && !currentPosition.name && !isInHeader">
  {{ 'LOCATION_SELECTOR.EXPLANATION' | translate }}
</p>
