import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const product = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'product']) || fromJS({});
    case actionTypes.PRODUCT_FETCH_INIT:
    case actionTypes.PRODUCT_FETCH_FAIL:
      const fail_id = action.data.get('id');
      const fail_status = action.data.get('status');
      return state.setIn([fail_id, 'status'], fail_status);
    case actionTypes.PRODUCT_FETCH_SUCCESS:
      const success_id = action.data.get('id');
      const product = action.data;
      return state.set(success_id, product);
    case actionTypes.RELATEDPRODUCTS_FETCH_INIT:
    case actionTypes.RELATEDPRODUCTS_FETCH_FAIL:
      const related_fail_id = action.data.get('id');
      const related_fail_status = action.data.get('status');
      return state.setIn([related_fail_id, 'relatedProducts', 'status'], related_fail_status);
    case actionTypes.RELATEDPRODUCTS_FETCH_SUCCESS:
      const data = action.data;
      const id = data.get('id');
      return state.setIn(
        [id, 'relatedProducts'],
        data.set(
          'response',
          data.get('response').filter((product) => product.get('id').toString() !== id)
        )
      );

    case actionTypes.PRODUCT_SET_FAVORITE:
      const listingId = parseInt(action.data.get('id'), 10);
      const isFavorite = action.data.get('isFavorite');

      return state.map((listing) =>
        listing.getIn(['response', 'id']) === listingId
          ? listing.setIn(['response', 'isFavorite'], isFavorite)
          : listing
      );

    case actionTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default product;
