import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

export interface DateRange {
  startDate: Date;
  endDate: Date;
  label: string;
}

@Injectable()
export class DateService {
  private dateRange: DateRange = null;
  private $dateRange = new Subject<DateRange>();

  constructor(
    public translate: TranslateService,
    public router: Router) { }

  public getDateRange(): DateRange {
    return this.dateRange;
  }

  public setDateRange(dateRange: DateRange) {
    this.dateRange = dateRange;
    this.$dateRange.next(<DateRange>dateRange);
  }


  public openDialog() {
    this.router.navigate([{ outlets: { modal: ['product', 'select-date'] } }], {
      skipLocationChange: true,
    });
  }

  public onDateRangeChange(): Observable<any> {
    return this.$dateRange;
  }

  public toString(): string {
    if (!this.dateRange) {
      return '';
    }
    return this.dateRange.label;
  }
}
