import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { orderAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const archiveOrder = (dispatch: Dispatch<any>) => (id: number) => {
  dispatch({
    data: fromJS({ id }),
    type: actionTypes.ORDER_ARCHIVE
  });

  orderAPI.archiveOrder(id);
};
