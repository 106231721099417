import getAPIURI from '../getAPIURI';
import request, { RequestResponseType } from '../../utils/request';


import eventTypes from '../../types/eventTypes';
import EventEmitter from '../../utils/EventEmitter';

const ee = new EventEmitter();

export enum BankIDType {
  BankIDSE = 'se',
  BankIDNO = 'no',
  BankIDDK = 'dk',
  BankIDFI = 'fi',
}

declare const window: any, navigator: any;

const publicBankIDAPI = {

  /* eslint-disable  */
  async getCredentialsByBankID({ isLogin, isSilent = false, method = BankIDType.BankIDSE, intent, otherDevice }: { isLogin: boolean; isSilent?: boolean; method: BankIDType; intent: string; otherDevice?: boolean; }) {
    return new Promise((resolve, reject) => {

      let allowErrors = 1;

      const uri = getAPIURI.initializeBankID(method);
      const headers = {
        'Content-Type': 'application/json'
      };
      type InitRequestResponse = {
        bankidLoginUrl?: string;
        authenticationToken: string;
        autoStartToken?: string;
      };
      let response: RequestResponseType<InitRequestResponse>;
      const options = {
        headers: headers,
        uri,
        method: 'GET',
      };

      request(options, isSilent)
        .then((response: any) => {
          const responseBody = JSON.parse(response.body);
          const autoStartToken = responseBody.autoStartToken;

          if ([BankIDType.BankIDNO, BankIDType.BankIDDK, BankIDType.BankIDFI].includes(method)) {
            const { bankidLoginUrl } = responseBody;
            ee.events.emit(eventTypes.BANKID_INITIATED, { bankidLoginUrl })
          } else if (!otherDevice && method === BankIDType.BankIDSE) {
            const deviceOS = getMobileOperatingSystem();
            const redirectUrl = deviceOS === 'ios' || deviceOS === 'wp' ? encodeURIComponent(window.location.href + '#' + Math.floor(Math.random() * 100000) + 1) : 'null';
            window.open('bankid:///?autostarttoken=' + autoStartToken + '&redirect=' + redirectUrl, '_self');
          } else {

            ee.events.emit(eventTypes.BANKD_AUTH_TOKEN_RECEIVED, { autoStartToken });
          }

          publicBankIDAPI.doCollectBankID(response, method, intent)
            .then((response: any) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        })
        .catch((err: any) => {
          const responseBody = JSON.parse(err.body);
          if (responseBody.error.code === 'ALREADY_IN_PROGRESS') {
            reject(eventTypes.BANKID_ALREADY_IN_PROGRESS)
          } else if (allowErrors > 0 && err.statusCode == 400) { //FIXME Allows one 500 error (if trying to login very quickly after an aborted attempt)
            allowErrors = allowErrors - 1;
            request(options, isSilent)
              .then((response: any) => {
                publicBankIDAPI.doCollectBankID(response, method, intent)
                  .then((response: any) => {
                    resolve(response);
                  })
                  .catch((err: any) => {
                    reject(err);
                  });
              })
              .catch((err: any) => {
                reject(err);
              });
          } else {
            reject(err);
          }
        });
    });
  },

  /* eslint-disable */
  doCollectBankID(data, method: BankIDType, intent: string | undefined) {
    let timesToPoll = 180;
    let previousPollStatus;

    return new Promise(pollCollectUrl);

    function pollCollectUrl(resolve, reject): void {
      const uri = getAPIURI.collectBankID(method);
      const headers = {
        'Content-Type': 'application/json'
      };
      const options = {
        body: data.body,
        headers: headers,
        uri,
        method: 'POST',
      };

      request(options, true)
        .then((response: any) => {
          ee.events.on(eventTypes.USER_LOGIN_ABORT, () => {
            timesToPoll = 0;
            reject(eventTypes.USER_LOGIN_ABORT);
          });

          const responseBody = JSON.parse(response.body);
          if (previousPollStatus != responseBody.progressStatus) {
            previousPollStatus = responseBody.progressStatus;
            if (responseBody.progressStatus == 'USER_SIGN') {
              ee.events.emit(eventTypes.BANKID_USER_IN_APP);
            }
          }

          if (responseBody.progressStatus == 'COMPLETE') {
            let collectData = JSON.parse(response.body);
            publicBankIDAPI.doCompleteBankID(JSON.stringify({ ...collectData, intent }))
              .then((response) => resolve(response))
              .catch((err) => reject(err));
          } else if (timesToPoll === 0) {
            reject(eventTypes.USER_LOGIN_TIMEOUT);
          } else {
            timesToPoll = timesToPoll - 1;
            setTimeout(pollCollectUrl.bind(this, resolve, reject), 1000);
          }
        })
        .catch((err: any) => {
          // In case when app stopped because of being in a background try to poll more
          if (!err) {
            timesToPoll = timesToPoll - 1;
            setTimeout(pollCollectUrl.bind(this, resolve, reject), 1000);
          }
          const responseBody = JSON.parse(err.body);
          if (responseBody.error.code === 'USER_CANCEL') {
            reject(eventTypes.USER_LOGIN_ABORT)
          } else if (responseBody.error.code === 'START_FAILED' || responseBody.error.code === 'EXPIRED_TRANSACTION') {
            reject(eventTypes.USER_LOGIN_TIMEOUT)
          } else  {
            timesToPoll = timesToPoll - 1;
            reject(err);
          }
        });
    }
  },

  doCompleteBankID(data) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.completeBankID();
      const headers = {
        'Content-Type': 'application/json'
      };
      const options = {
        body: data,
        headers: headers,
        uri,
        method: 'POST',
      };
      request(options, true)
        .then((response: any) => {
          resolve(JSON.parse(response.body));
        })
        .catch((err: any) => {
          reject(err);
        });
    }
    );
  },

};

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return 'wp';
  }

  if (/android/i.test(userAgent)) {
    return 'android';
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios';
  }

  return 'other';
}

export default publicBankIDAPI;
