import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

const privateProductAPI = {
  /* eslint-disable */
  postProductReview(orderId: any, productId: any, rating: any, reviewText: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.postProductReview(productId);
      const body = {
        orderId,
        productId,
        rating,
        reviewText,
      };
      const options = {
        json: body,
        uri,
        method: 'POST',
        intent: 'post product review',
      };
      request(options)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  },
};

export default privateProductAPI;
