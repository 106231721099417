import { environment } from '../environments/environment';

const getAPIConfig = {
  /* eslint-disable   */
  clientId(): string {
    return 'ngHyggloApp';
  },

  /* eslint-disable  */
  clientSecret(): string {
    return 'lQVS05DGy9SQdAEInEPqTMK3aktEfSc7iupC7BYM4JY=';
  },

  stripePublishableKey(): string {
    // return 'pk_live_2Aw6Qa3VYtbzaU9teQpPh2ci'
    return environment.stripe_token;
  },

  googleBrowserApiKey(): string {
    return 'AIzaSyB6NZw8fBmb-K6T4aT_XlZy2jIVPqrjmOQ';
  }
};

export default getAPIConfig;
