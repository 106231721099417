<a (click)="selectDates()">
  <i></i>

  <span class="prefix" *ngIf="dateString !== ''">{{ 'DATE_SELECTOR.RENTAL_PERIOD' | translate }} </span>

  <span class="dates"
    >{{ (dateString !== '' ? 'DATE_SELECTOR.DATES.TRUE' : 'DATE_SELECTOR.DATES.FALSE') | translate:this }}&nbsp;</span
  >

  <span class="cta">{{ 'DATE_SELECTOR.CHANGE' | translate }}</span>
</a>
