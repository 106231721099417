import { Injectable } from '@angular/core';
import { CategoryService, FilteredCategoryType, CategoryType } from '@hygglo/categories';
import { NgRedux } from '@angular-redux/store';
import { TranslateService } from '@ngx-translate/core';
import { CategoriesActions } from 'src/actions/categories';

@Injectable()
export class WebCategoryService extends CategoryService {
  constructor(
    private ngRedux: NgRedux<any>,
    translate: TranslateService,
    public categoriesActions: CategoriesActions
  ) {
    super();

    translate.onLangChange.subscribe(() => {
      if (this.isLoaded()) {
        categoriesActions.fetchCategories();
      }
    });
  }

  async filterCategoriesAsync(
    searchString: string,
    productSearch?: boolean
  ): Promise<FilteredCategoryType[]> {
    await this.verifyLoaded();
    return super.filterCategories(searchString, productSearch);
  }

  async getCategoryByIdAsync(categoryId: number): Promise<CategoryType> {
    await this.verifyLoaded();
    return super.getCategoryById(categoryId);
  }

  public async verifyLoaded(): Promise<void> {
    if (this.isLoaded()) {
      // already loaded
      return;
    } else {
      // not loaded, loading
      await this.loadCategories();
      return;
    }
  }

  public isLoaded(): boolean {
    return this.filterCategories('a').length > 0;
  }

  public async getCachedCategoryBySlug(slug: string, locationSlug?: string) {
    return await this.categoriesActions.fetchCategory(slug, true, locationSlug);
  }

  private async loadCategories(): Promise<void> {
    const categories = await this.categoriesActions.fetchCategories();
    super.updateData(categories);
  }
}
