import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const topAPI = {
  async getTopCategories(days?: number) {
    const options = {
      uri: buildUrl('/v2/top/categories', { limit: 20, days }),
      method: 'GET',
      simple: true
    };

    const { body } = await request(options, true);
    const json = JSON.parse(body);

    return json;
  }
};
