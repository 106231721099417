import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Angulartics2Module } from 'angulartics2';

const appRoutes: Routes = [
  /*
    PLEASE NOTE: Always add new routes to server.js as well
    (otherwise they will give hard 404)
  */
  {
    path: 'hyra-ut',
    loadChildren: () => import('./modules/start/start.module').then((m) => m.StartModule),
  },
  {
    path: 'lei-ut',
    loadChildren: () => import('./modules/start/start.module').then((m) => m.StartModule),
  },
  {
    path: 'jata-ilmoitus',
    loadChildren: () => import('./modules/start/start.module').then((m) => m.StartModule),
  },
  {
    path: 'udleje',
    loadChildren: () => import('./modules/start/start.module').then((m) => m.StartModule),
  },
  {
    path: 'fakta',
    loadChildren: () => import('./modules/fact/fact.module').then((m) => m.FactModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
  },
  {
    path: 'user',
    loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
    outlet: 'modal',
  },
  {
    path: 'codes',
    loadChildren: () => import('./modules/coupon/coupon.module').then((m) => m.CouponModule),
  },
  {
    path: 'invoices',
    loadChildren: () => import('./modules/invoices/invoices.module').then((m) => m.InvoicesModule),
  },
  {
    path: 'myfavorites',
    loadChildren: () =>
      import('./modules/favorites/favorites.module').then((m) => m.FavoritesModule),
  },
  {
    path: 'location',
    loadChildren: () => import('./modules/location/location.module').then((m) => m.LocationModule),
  },
  {
    path: 'location/:locationSlug',
    redirectTo: 'location/:locationSlug/oversikt',
    pathMatch: 'full',
  },
  {
    path: 'password-reset',
    redirectTo: 'user/password-reset',
    pathMatch: 'full',
  },
  {
    path: 'location/:locationSlug/:page',
    loadChildren: () => import('./modules/location/location.module').then((m) => m.LocationModule),
  },
  {
    path: 'users/:slug',
    loadChildren: () =>
      import('./modules/profile-page/profile-page.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/profile-page/profile-page.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./modules/order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'shared/:orderHash',
    loadChildren: () =>
      import('./modules/shared-order/shared-order.module').then((m) => m.SharedOrderModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./modules/search-results/search-results.module').then((m) => m.SearchResultsModule),
  },
  {
    path: 'category',
    loadChildren: () =>
      import('./modules/search-results/search-results.module').then((m) => m.SearchResultsModule),
  },
  {
    path: 'categories',
    loadChildren: () =>
      import('./modules/search-results/search-results.module').then((m) => m.SearchResultsModule),
  },
  {
    path: 'campaign',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'tos',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'integritetspolicy',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'gavekort',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'confirmation',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'page',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'om-oss',
    loadChildren: () =>
      import('./modules/static-content/static-content.module').then((m) => m.StaticContentModule),
  },
  {
    path: 'createproduct',
    redirectTo: 'myproducts/createproduct',
    pathMatch: 'full',
  },
  {
    path: 'myproducts',
    loadChildren: () =>
      import('./modules/edit-product/edit-product.module').then((m) => m.EditProductModule),
  },
  {
    path: 'myproducts',
    loadChildren: () =>
      import('./modules/edit-product/edit-product.module').then((m) => m.EditProductModule),
    outlet: 'modal',
  },
  {
    path: 'product',
    loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
    outlet: 'modal',
  },
  {
    path: 'i/:slug',
    loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
  },
  {
    path: ':slug',
    redirectTo: 'i/:slug',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./modules/start/start.module').then((m) => m.StartModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
    Angulartics2Module.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
