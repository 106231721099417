<div class="search-wrapper">
  <form class="search" (ngSubmit)="go()">
    <input
      class="data-hj-whitelist"
      type="text"
      autocomplete="off"
      matInput
      #searchInput
      placeholder="{{ 'SEARCH.FILTER_PLACEHOLDER' | translate }}"
      [(ngModel)]="query"
      [ngModelOptions]="{ standalone: true }"
      (input)="inputChanged(query)"
      (click)="inputChanged(query)"
    />

    <button class="search-button"></button>
    <app-autocomplete [input]="searchInput">
      <mat-optgroup
        label="{{ 'SEARCH.CATEGORY_SUGGESTION' | translate }}"
        *ngIf="filteredCategoryList && filteredCategoryList.length > 0"
      >
        <mat-option
          *ngFor="let category of filteredCategoryList"
          [value]="category"
          (click)="searchSuggestionSelected(category)"
        >
          {{ category.name }} <div class="subtext">{{ category.breadcrumb.text }}</div>
        </mat-option>
      </mat-optgroup>

      <mat-optgroup
        label="{{ 'SEARCH.SEARCH_LABEL' | translate }}"
        *ngIf="autoCompleteSuggestions && autoCompleteSuggestions.length > 0"
      >
        <mat-option
          *ngFor="let suggestion of autoCompleteSuggestions"
          [value]="suggestion.suggestion"
          (click)="search(suggestion.suggestion)"
        >
          {{ suggestion.suggestion }}
        </mat-option>
      </mat-optgroup>
    </app-autocomplete>
  </form>

  <div class="search-options">
    <location-selector [isInHeader]="true"></location-selector>
    <date-selector></date-selector>
  </div>
</div>
