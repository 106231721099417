<header
  class="mobile-header hide-for-large {{ isStartPage ? 'is-startpage' : '' }} {{ isOpaque ? 'is-opaque' : '' }} {{
    mobileSearchExpanded ? 'is-expanded' : ''
  }}"
>
  <div class="row">
    <div class="small-12 columns mobile-header-search {{ mobileSearchExpanded ? 'is-expanded' : '' }}">
      <search *ngIf="searchInit"></search>
    </div>

    <div class="small-12 columns">
      <div class="mobile-header-inner">
        <button class="nav-button toggle-sidenav" (click)="sideNav.toggle()"></button>
        <div class="badge" *ngIf="notificationCount || profileProblems">{{ notificationCount + profileProblems }}</div>

        <a [routerLink]="['/']" class="logo" rel="home">
          <img
            *ngIf="!isItValentines"
            class="logo-img"
            src="https://hygglo-web-static.imgix.net/logo-browless-old.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            *ngIf="isItValentines"
            class="logo-img"
            src="https://hygglo-web-static.imgix.net/logo-browless-valentines.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            class="logo-img-left"
            src="https://hygglo-web-static.imgix.net/logo-left-brow.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            class="logo-img-right"
            src="https://hygglo-web-static.imgix.net/logo-right-brow.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            *ngIf="isItXmas"
            class="logo-img xmas"
            src="https://hygglo-web-static.imgix.net/logo-xmas-hat.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
        </a>

        <button
          class="nav-button toggle-search {{ mobileSearchExpanded ? 'is-expanded' : '' }}"
          (click)="toggleMobileSearch()"
        ></button>
      </div>
    </div>
  </div>
</header>

<header class="desktop-header show-for-large {{ isStartPage ? 'is-startpage' : '' }}">
  <div class="row">
    <div *ngIf="!isLandingPage" class="small-12 columns top-nav">
      <ul>
        <li
          ><a (click)="this.openFaq('5203904-how-hygglo-works-in-short')">{{
            'HEADER.HOW_IT_WORKS' | translate
          }}</a></li
        >
        <li
          ><a (click)="this.openFaq('5223009-general-insurance-when-renting-out')">{{
            'HEADER.INSURANCE' | translate
          }}</a></li
        >
        <li
          ><a (click)="this.openFaq()">{{ 'HEADER.FAQ' | translate }}</a></li
        >
        <li
          ><a routerLink="/om-oss/kontakt">{{ 'HEADER.CONTACT' | translate }}</a></li
        >
      </ul>
    </div>
    <div class="small-12 columns">
      <div class="header-inner">
        <a [routerLink]="['/']" class="logo" rel="home" [ngClass]="{ isStartPage: isStartPage }">
          <img
            *ngIf="!isItValentines"
            class="logo-img"
            src="https://hygglo-web-static.imgix.net/logo-browless-old.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            *ngIf="isItValentines"
            class="logo-img"
            src="https://hygglo-web-static.imgix.net/logo-browless-valentines.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            class="logo-img-left"
            src="https://hygglo-web-static.imgix.net/logo-left-brow.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            class="logo-img-right"
            src="https://hygglo-web-static.imgix.net/logo-right-brow.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
          <img
            *ngIf="isItXmas"
            class="logo-img xmas"
            src="https://hygglo-web-static.imgix.net/logo-xmas-hat.svg"
            alt="{{ 'HEADER.HYGGLO_LOGOTYPE_ALT' | translate }}"
          />
        </a>
        <search class="desktop" *ngIf="!isStartPage"></search>

        <div class="main-nav">
          <button
            *ngIf="!isOnVendorPage && !isLandingPage"
            class="inline-button green"
            routerLink="{{ 'HEADER.RENT_OUT_LINK' | translate }}"
          >
            {{ 'HEADER.RENT_OUT' | translate }}
          </button>
          <button *ngIf="isOnVendorPage" class="inline-button green" routerLink="/createproduct">{{
            'HEADER.CREATE_AD' | translate
          }}</button>
          <button class="inline-button clear" *ngIf="!firstName" (click)="login()">{{
            'HEADER.LOGIN' | translate
          }}</button>
          <div class="my-profile" *ngIf="firstName">
            <div class="badge" *ngIf="notificationCount || profileProblems">{{
              notificationCount + profileProblems
            }}</div>
            <a *ngIf="!firstName" mat-button color="primary" (click)="login()">
              <mat-icon>person</mat-icon>
              &nbsp;{{ firstName ? firstName : ('HEADER.LOGIN' | translate) }}
            </a>

            <div class="profile-image" *ngIf="firstName" [matMenuTriggerFor]="appMenu">
              <fancy-img
                [src]="profileImage || 'https://hygglo-web-static.imgix.net/ui/avatar-placeholder.png?auto=format'"
              ></fancy-img>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<mat-menu #appMenu="matMenu">
  <button mat-menu-item routerLink="/orders" routerLinkActive="active" *ngIf="isLoggedIn">
    {{ 'HEADER.MY_ORDERS' | translate }}
    <div class="badge" *ngIf="notificationCount">{{ notificationCount }}</div>
  </button>
  <button mat-menu-item routerLink="/myproducts" routerLinkActive="active" *ngIf="isVendor">{{
    'HEADER.MY_ADS' | translate
  }}</button>
  <button mat-menu-item routerLink="/user/profile" routerLinkActive="active" *ngIf="isLoggedIn"
    >{{ 'HEADER.EDIT_PROFILE' | translate }}
    <div class="badge" *ngIf="profileProblems">{{ profileProblems }}</div></button
  >
  <button mat-menu-item routerLink="/users/{{ slug }}" routerLinkActive="active" *ngIf="isVendor">{{
    'HEADER.PUBLIC_PROFILE' | translate
  }}</button>
  <button mat-menu-item routerLink="/invoices" routerLinkActive="active" *ngIf="isVendor">{{
    'HEADER.TRANSACTIONS' | translate
  }}</button>
  <button mat-menu-item routerLink="/codes" routerLinkActive="active" *ngIf="isLoggedIn">{{
    'HEADER.CODES' | translate
  }}</button>
  <button mat-menu-item routerLink="/myfavorites" routerLinkActive="active" *ngIf="isLoggedIn">{{
    'HEADER.FAVORITES' | translate
  }}</button>
  <button mat-menu-item [matMenuTriggerFor]="language" routerLinkActive="active" *ngIf="isLoggedIn">{{
    'HEADER.LANGUAGE' | translate
  }}</button>
  <mat-menu #language="matMenu">
    <button mat-menu-item [disabled]="currentLang === 'en'" (click)="changeLanguage('en')">English</button>
    <button mat-menu-item [disabled]="currentLang === 'da'" (click)="changeLanguage('da')">Dansk</button>
    <button mat-menu-item [disabled]="currentLang === 'nb'" (click)="changeLanguage('nb')">Norsk</button>
    <button mat-menu-item [disabled]="currentLang === 'fi'" (click)="changeLanguage('fi')">Suomi</button>
    <button mat-menu-item [disabled]="currentLang === 'sv'" (click)="changeLanguage('sv')">Svenska</button>
  </mat-menu>
  <button mat-menu-item (click)="this.emitLogout()">{{ 'HEADER.LOGOUT' | translate }}</button>
</mat-menu>
