import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const categoriesAPI = {
  fetchCategories() {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/categories/');
      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => {
          resolve(response.body);
        })
        .catch((err): any => reject(err));
    });
  },

  fetchCategory(slug: string, silent?: boolean, location?: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl(`/v2/categories/${slug}`, { location });
      const options = {
        responseType: 'json',
        uri,
        simple: true,
      };
      request(options, silent)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  searchCategories(keywords: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/categories/search', { keywords });
      const options = {
        uri,
        responseType: 'json',
      };
      request(options, true)
        .then((response: any): any => {
          resolve(response.body);
        })
        .catch((err): any => reject(err));
    });
  },
};
