/// <reference path='../../node_modules/immutable/dist/immutable.d.ts'/>

import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';

const initialState = fromJS({});

const reviews = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.FETCH_RATINGS_REVIEWS:
      const dataResponse = action.data.get('response');
      return state.set('reviewsRating', dataResponse);
    default:
      return state;
  }
};

export default reviews;
