import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { productAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const removeAsFavorite = (dispatch: Dispatch<any>) => (id: string) => {
  const setSuccessState = () => {
    return {
      data: fromJS({
        id,
        isFavorite: false
      }),
      type: actionTypes.PRODUCT_SET_FAVORITE
    };
  };

  return new Promise((resolve, reject) => {
    productAPI
      .removeAsFavorite(id)
      .then(
        (response): any => {
          resolve(response);
          dispatch(setSuccessState());
        }
      )
      .catch((err) => reject(err));
  });
};
