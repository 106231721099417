import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
import settingsPropTypes from './../types/settingsPropTypes';

const initialState = fromJS({});

const settings = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      const onboarding = action.data.getIn([
        'response',
        'settings',
        settingsPropTypes.ONBOARDING_CONSUMED
      ]);
      return (action.data.getIn(['response', 'settings']) || fromJS({})).set(
        settingsPropTypes.ONBOARDING_CONSUMED,
        !!onboarding
      );
    case actionTypes.SETTINGS_SET_PROP:
      return state.merge(action.data);
    // case actionTypes.CLEAR_STATE:
    //   return initialState;
    default:
      return state;
  }
};

export default settings;
