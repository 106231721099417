import getAPIURI from '../getAPIURI';
import request from '../../utils/request';
import { fetchPaidOutOrders } from '../../actions/order/fetchPaidOutOrders';

const privateOrderAPI = {
  /* eslint-disable  */
  createOrder(body: any, intent: string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.createOrder();
      const options = {
        uri,
        json: body,
        method: 'POST',
        intent: intent,
      };
      request(options)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  /* eslint-disable  */
  createOrderAsVendor(body: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.createOrderAsVendor();
      const options = {
        uri,
        json: body,
        method: 'POST',
        intent: 'create order as vendor',
      };
      request(options)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  /* eslint-disable  */
  createInitiatedOrder(id: string, body: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.createInitiatedOrder(id);
      const options = {
        uri,
        json: body,
        method: 'PUT',
        intent: 'create initiated order',
      };
      request(options)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  /* eslint-disable  */
  sendMessage(id: string, message: string, image?: string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.sendMessage(id);
      const body = {
        id,
        message,
        image,
      };
      const options = {
        uri,
        json: body,
        method: 'PUT',
        intent: 'send message',
      };
      request(options, true)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  },

  /* eslint-disable */
  updateOrderStatus(id: string, orderStatus: string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.updateOrderStatus(id);
      const body = {
        id,
        orderStatus,
      };
      const options = {
        uri,
        json: body,
        method: 'PUT',
        intent: 'update order status: ' + orderStatus,
      };
      request(options)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  },

  countOrders(silent?) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.countOrders();
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'count orders',
      };
      request(options, silent)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchOrders(
    role: string,
    filter: string,
    sort: string,
    offset: number,
    limit: number,
    silent?: boolean
  ) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.fetchOrders(role, filter, sort, offset, limit);
      console.info(uri);
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'fetch orders',
      };
      request(options, silent)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchOrder(id: string, silent?: boolean) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.fetchOrder(id);
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'fetch order',
      };
      request(options, silent)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchPaidOutOrders(silent?: boolean) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.fetchPaidOutOrders();
      console.info(uri);
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'fetch paid out orders',
      };
      request(options, silent)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },
};

export default privateOrderAPI;
