import storage from '../../utils/storage';
import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { Dispatch } from 'redux';

// Success.
const setSuccessState = (response) => {
  return {
    data: fromJS({
      response: response,
      status: 'SUCCESS'
    }),
    type: actionTypes.STORAGE_FETCH_SUCCESS
  };
};

// Error.
const setErrorState = (error) => {
  return {
    data: fromJS({
      response: error,
      status: 'ERROR'
    }),
    type: actionTypes.STORAGE_FETCH_FAIL
  };
};

const setLoadingState = () => {
  return {
    data: fromJS({
      response: {},
      status: 'LOADING'
    }),
    type: actionTypes.STORAGE_FETCH_INIT
  };
};

export const fetchPersistentStorage = (dispatch: Dispatch<any>) => {
  return () => {
    dispatch(setLoadingState());

    const data = storage
      .get('data')
      .then((response: any) => {
        if (response) {
          dispatch(setSuccessState(JSON.parse(response)));
        } else {
          dispatch(setSuccessState({}));
        }
      })
      .catch((err) => {
        dispatch(setErrorState(err));
      });
  };
};
