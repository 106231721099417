import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

const privateAccountAPI = {
  /* eslint-disable  */
  postDeviceInfo(deviceType: string, deviceToken: string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.postDeviceInfo();
      const body = {
        deviceType,
        deviceToken,
      };
      const options = {
        uri,
        json: body,
        method: 'POST',
        intent: 'post device info',
      };
      request(options, true)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  /* eslint-disable */
  async fetchAccount(silent: boolean = false, intent: string = 'fetch account') {
    const uri = getAPIURI.fetchAccount();
    const options = {
      uri,
      method: 'GET',
      responseType: 'json',
      intent,
    };
    const { body } = await request(options, silent);

    return body;
  },

  updateAccount(userData: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.updateAccount();
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        body: userData,
        headers,
        uri,
        method: 'PUT',
        intent: 'update account',
      };
      request(options)
        .then((response: any) => resolve(response))
        .catch((err: any) => reject(err));
    });
  },

  uploadIdentity(documents: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.uploadIdentity();
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        json: documents,
        headers,
        uri,
        method: 'POST',
        intent: 'update account',
      };
      request(options)
        .then((response: any) => resolve(response))
        .catch((err: any) => reject(err));
    });
  },

  verifyAccount(data: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.verifyAccount();
      const headers = {
        'Content-Type': 'application/json',
      };
      const body = {
        data,
      };
      const options = {
        json: body,
        headers,
        uri,
        method: 'POST',
        intent: 'verify account',
      };
      request(options)
        .then((response: any) => resolve(response))
        .catch((err: any) => reject(err));
    });
  },

  verifyAccountStatus() {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.verifyAccount();
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'verify account status',
      };
      request(options, true)
        .then((response: any) => resolve(response.body))
        .catch((err: any) => reject(err));
    });
  },

  getVendorOnboardingLink() {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.vendorOnboardingLink();
      const options = {
        uri,
        method: 'GET',
        responseType: 'json',
        intent: 'vendor onboarding link',
      };
      request(options, true)
        .then((response: any) => resolve(response.body))
        .catch((err: any) => reject(err));
    });
  },

  logOut() {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.logOut();
      const headers = {
        'Content-Type': 'application/json',
      };
      const options = {
        headers,
        uri,
        method: 'DELETE',
        intent: 'logout',
      };
      request(options, false, false, true)
        .then((response: any) => resolve(response))
        .catch((err: any) => reject(err));
    });
  },
};

export default privateAccountAPI;
