import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const account = (state = fromJS({}), action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'account']) || fromJS({});
    case actionTypes.ACCOUNT_LOGIN_INIT:
    case actionTypes.ACCOUNT_LOGIN_SUCCESS:
    case actionTypes.ACCOUNT_LOGIN_FAIL:
      return action.data;
    case actionTypes.ACCOUNT_UPDATE_INFO:
      return state.merge(action.data);
    case actionTypes.CLEAR_STATE:
      return initialState;
    case actionTypes.CLEAR_ACCOUNT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default account;
