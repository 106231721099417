import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { publicAccountAPI } from '../../webapi/public/index';
import { privateAccountAPI } from '../../webapi/private/index';
import EventEmitter from '../../utils/EventEmitter';
import eventTypes from '../../types/eventTypes';
import { Dispatch } from 'redux';

const ee = new EventEmitter();

const setLoadingStateAccountUserLogin = () => {
  return {
    data: fromJS({
      status: 'LOADING'
    }),
    type: actionTypes.ACCOUNT_LOGIN_INIT
  };
};

const setErrorStateAccountLogin = (error) => {
  ee.events.emit(eventTypes.EMAIL_LOGIN_FAIL, error);
  return {
    data: fromJS({
      status: 'ERROR'
    }),
    type: actionTypes.ACCOUNT_LOGIN_FAIL
  };
};

const setSuccessStateAccountLogin = (response, inData) => {
  ee.events.emit(eventTypes.ACCOUNT_LOGIN_SUCCESS, inData.loginResponse);
  return {
    data: fromJS({
      response: response,
      status: 'SUCCESS'
    }),
    type: actionTypes.ACCOUNT_LOGIN_SUCCESS
  };
};

const fetchAccount = (dispatch: Dispatch<any>) => (loginResponse) => {
  privateAccountAPI
    .fetchAccount()
    .then((response) => dispatch(setSuccessStateAccountLogin(response, { loginResponse })))
    .catch((err) => dispatch(setErrorStateAccountLogin(err)));
};

export const loginAccount = (dispatch: Dispatch<any>) => (
  usernameOrEmail: string,
  password: string
) => {
  dispatch(setLoadingStateAccountUserLogin());
  publicAccountAPI
    .loginAccount(usernameOrEmail, password)
    .then(fetchAccount(dispatch))
    .catch((err) => dispatch(setErrorStateAccountLogin(err)));
};
