import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

const privateCustomersAPI = {
  /* eslint-disable */
  postCustomerReview(orderId: any, productId: any, customerId: any, rating: any, reviewText: any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.publishCustomerReview(customerId);
      const body = {
        orderId,
        productId,
        customerId,
        rating,
        reviewText
      };
      const options = {
        json: body,
        uri,
        method: 'POST',
        intent: 'post customer review'
      };
      request(options)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  }
};

export default privateCustomersAPI;
