import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const newAccount = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'newAccount']) || fromJS({});
    case actionTypes.NEWACCOUNT_UPDATE_INFO:
      return state.merge(action.data);
    case actionTypes.CLEAR_STATE:
    case actionTypes.CLEAR_NEW_ACCOUNT_STATE:
      return initialState;
    default:
      return state;
  }
};

export default newAccount;
