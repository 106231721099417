import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const couponAPI = {
  redeemCoupon(json: { token: string }) {
    const uri = buildUrl(`/v2/my/coupons`);

    return request({ json, uri, method: 'POST' }, true);
  },

  fetchCouponTransactions(silent: boolean) {
    const uri = buildUrl(`/v2/my/coupon-transactions`);

    return request({ uri, method: 'GET' }, silent);
  }
};
