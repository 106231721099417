import { Injectable } from '@angular/core';
import HyggloSocketClient from '@hygglo/socket-client';
import { DeviceIdService } from './device-id.service';
import { buildUrl } from '../../webapi/getAPIURI';

@Injectable()
export class SocketService {

  public socket: HyggloSocketClient;

  constructor(public deviceIdService: DeviceIdService) {

  }

  public connect() {
    this.socket = new HyggloSocketClient({
      client: 'Hygglo-web',
      apiUrl: buildUrl('')
    });
    this.socket.init({ deviceId: this.deviceIdService.get() });
  }

  public logout() {
    if(this.socket) {
      this.socket.logout();
    }
  }
}
