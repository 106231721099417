import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { searchAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const fetchMyFavorites = (dispatch: Dispatch<any>) => (silent?: boolean) => {
  const setLoadingState = () => {
    return {
      data: fromJS({
        response: [],
        status: 'LOADING'
      }),
      type: actionTypes.FAVORITES_FETCH_INIT
    };
  };

  const setErrorState = (error) => {
    return {
      data: fromJS({
        response: error,
        status: 'ERROR'
      }),
      type: actionTypes.FAVORITES_FETCH_FAIL
    };
  };

  const setSuccessState = (response) => {
    return {
      data: fromJS({
        response: response,
        status: 'SUCCESS'
      }),
      type: actionTypes.FAVORITES_FETCH_SUCCESS
    };
  };

  dispatch(setLoadingState());
  searchAPI
    .fetchFavoriteListings()
    .then((response): any => dispatch(setSuccessState(response)))
    .catch((err): any => dispatch(setErrorState(err)));
};
