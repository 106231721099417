import { environment } from '../environments/environment';
import isNumber from 'is-number';

const { api_url: BASE, noBankIdUseTestServer } = environment; // profit?

export const buildUrl = (
  path: string,
  query: { [key: string]: string | number | void | null | Date | boolean } = {}
): string => {
  const url = new URL(`${BASE}${path}`);

  Object.keys(query)
    .map((key): [string, string | number | void | null | Date | boolean] => [key, query[key]])
    .forEach(([key, value]) => {
      if (value || isNumber(value)) {
        url.searchParams.set(key, String(value));
      }
    });

  return url.href;
};

type BankIDTypeOptions = 'fi' | 'dk' | 'no' | 'se';

const getAPIURI = {
  getCurrentBase(): string {
    return BASE;
  },

  token(): string {
    return buildUrl(`/token`);
  },

  registerUser(): string {
    return buildUrl(`/v1/account/register`);
  },

  fetchAccount(): string {
    return buildUrl(`/v1/account/current`);
  },

  postDeviceInfo(): string {
    return buildUrl(`/v1/account/current/device-info`);
  },

  updateAccount(): string {
    return buildUrl(`/v1/account/current`);
  },

  uploadIdentity(): string {
    return buildUrl(`/v2/my/identity-documents`);
  },

  verifyAccount(): string {
    return buildUrl(`/v1/account/current/verification`);
  },

  vendorOnboardingLink(): string {
    return buildUrl(`/v3/my/vendor-onboarding`);
  },

  logOut(): string {
    return buildUrl(`/v1/auth/sessions/current`);
  },

  initializeBankID(bankIdType: BankIDTypeOptions = 'se', ssn?: string): string {
    const testString = noBankIdUseTestServer ? '&test=true' : '';
    if (ssn) {
      return buildUrl(`/v1/bankid/initialize?bankIdType=${bankIdType}&ssn=${ssn}${testString}`);
    } else {
      return buildUrl(`/v1/bankid/initialize?bankIdType=${bankIdType}${testString}`);
    }
  },

  collectBankID(bankIdType: BankIDTypeOptions = 'se'): string {
    return buildUrl(`/v1/bankid/collect?bankIdType=${bankIdType}`);
  },

  completeBankID(): string {
    return buildUrl(`/v1/bankid/complete`);
  },

  reportAbuse(): string {
    return buildUrl(`/v1/abuse`);
  },

  fetchProductById(id: string): string {
    return buildUrl(`/v1/product/${id}`);
  },

  createOrder(): string {
    return buildUrl(`/v1/order`);
  },

  createOrderAsVendor(): string {
    return this.createOrder();
  },

  createInitiatedOrder(id: string): string {
    return buildUrl(`/v1/order/${id}`);
  },

  fetchOrder(id: string): string {
    return buildUrl(`/v1/order/${id}`);
  },

  sendMessage(id: string): string {
    return buildUrl(`/v1/order/${id}`);
  },

  updateOrderStatus(id: string): string {
    return buildUrl(`/v1/order/${id}`);
  },

  countOrders(): string {
    return buildUrl(`/v3/my/orders/count`);
  },

  fetchOrders(role: string, filter: string, sort: string, offset: number, limit: number): string {
    return buildUrl(`/v3/my/orders`, { role, filter, sort, offset, limit });
  },

  fetchPaidOutOrders(): string {
    return buildUrl(`/v3/my/orders/paid-out`);
  },

  postProductReview(id: string): string {
    return buildUrl(`/v1/product/${id}/reviews`);
  },

  publishCustomerReview(id: string): string {
    return buildUrl(`/v1/customers/${id}/reviews`);
  },

  payOrder(): string {
    return buildUrl(`/v1/payments`);
  },

  cancelPaidOrder(orderid: string): string {
    return buildUrl(`/v1/payments/${orderid}/refund`);
  },

  fetchStoredCard(): string {
    return buildUrl(`/v1/payments/stored-card`);
  },

  fetchNotifications(): string {
    return buildUrl(`/v1/notifications`);
  },

  initiatePasswordReset(): string {
    return buildUrl(`/v2/password-reset`);
  },

  completePasswordReset(token: string): string {
    return buildUrl(`/v2/password-reset/${token}`);
  },
};

export default getAPIURI;
