import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { privateOrderAPI } from '../../webapi/private/index';
import { Dispatch } from 'redux';

export const fetchPaidOutOrders =
  (dispatch: Dispatch<any>) =>
  async (silent: boolean = false) => {
    const setLoadingState = () => {
      return {
        data: fromJS({ status: 'LOADING' }),
        type: actionTypes.PAID_OUT_ORDERS_FETCH_INIT,
      };
    };

    const setErrorState = (error) => {
      return {
        data: fromJS({ status: 'ERROR' }),
        type: actionTypes.PAID_OUT_ORDERS_FETCH_FAIL,
      };
    };

    const setSuccessState = (response) => {
      return {
        data: fromJS({ response: response, status: 'SUCCESS' }),
        type: actionTypes.PAID_OUT_ORDERS_FETCH_SUCCESS,
      };
    };

    dispatch(setLoadingState());
    privateOrderAPI
      .fetchPaidOutOrders()
      .then((response): any => dispatch(setSuccessState(response)))
      .catch((err): any => dispatch(setErrorState(err)));
  };
