import getAPIConfig from '../getAPIConfig';
import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

import storage from '../../utils/storage';

const publicAccountAPI = {

  /* eslint-disable  */
  /* eslint-disable no-console */
  async loginAccount(usernameOrEmail:string, password:string) {
    const uri = getAPIURI.token();
    const clientSecret = getAPIConfig.clientSecret();
    const clientId = getAPIConfig.clientId();

    const options = {
      uri,
      form: {
        grant_type: 'password',
        username: usernameOrEmail,
        password,
        client_id: clientId,
        client_secret: clientSecret
      },
      method: 'POST',
      responseType: 'json',
    };

    const response = await request(options);
    try {
      await storage.set('tokens', JSON.stringify(response.body))
    }
    catch (err) {
      // do mothing
    }

    return response.body;
  },

  /* eslint-disable */
  createAccount(userData:any) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.registerUser();
      const headers = {
        'Content-Type': 'application/json'
      };
      const options = {
        body: userData,
        headers: headers,
        uri,
        method: 'POST',
      };
      request(options)
        .then((response:any) => resolve(response))
        .catch((err:any) => reject(err));
    });
  },
};

export default publicAccountAPI;
