import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { searchAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

export const fetchRelatedProducts = (dispatch: Dispatch<any>) => async (
  id: string,
  vendorId: string
) => {
  const setErrorState = (error) => {
    return {
      data: fromJS({
        id,
        status: 'ERROR'
      }),
      type: actionTypes.RELATEDPRODUCTS_FETCH_FAIL
    };
  };

  const setSuccessState = (response) => {
    return {
      data: fromJS({
        id,
        response: response.productListings,
        status: 'SUCCESS'
      }),
      type: actionTypes.RELATEDPRODUCTS_FETCH_SUCCESS
    };
  };

  // dispatch(setLoadingState());
  try {
    const response = await searchAPI.fetchListingsByVendor(vendorId);

    return dispatch(setSuccessState(response));
  } catch (err) {
    return dispatch(setErrorState(err));
  }
};
