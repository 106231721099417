/// <reference path='../../node_modules/immutable/dist/immutable.d.ts'/>

import actionTypes from '../types/actionTypes';
import * as typeGenerator from '../types/typeGenerator';
import { fromJS, Map, List} from 'immutable';

// type Category = Map<string, any>;
// type CategoryList = List<Category>;

const initialState = fromJS({});

const successReducer = (state, action) => {
  const list = action.data.get('response');
  return Map({
    status: action.data.get('status'),
    list
  });
};

const searchReducer = (state, action) => {
  const list = state.get('list') || List([]);
  const searchTerm = action.data.get('searchTerm').toLowerCase();
  const results = list.filter((category) =>
    category
      .get('name')
      .toLowerCase()
      .includes(searchTerm)
  );

  return state.set('search', Map({ results, searchTerm }));
};

const categories = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case typeGenerator.loading('categories'):
    case typeGenerator.error('categories'):
      const status = action.data.get('status');
      return state.setIn(['status'], status);
    case typeGenerator.success('categories'):
      return successReducer(state, action);
    case actionTypes.CATEGORIES_SEARCH:
      return searchReducer(state, action);
    default:
      return state;
  }
};

export default categories;
