import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

const makeOrderPatchRequest = (orderId, body) => {
  const uri = buildUrl(`/v2/my/orders/${orderId}`);
  const options = {
    json: body,
    uri,
    method: 'PATCH',
  };

  return request(options, true);
};

export const orderAPI = {
  updatePrice(id: number, price: number) {
    return makeOrderPatchRequest(id, { price });
  },

  makePartialRefund(id: number, refund: number) {
    return makeOrderPatchRequest(id, { refund });
  },

  archiveOrder(id: number) {
    return makeOrderPatchRequest(id, { isArchived: true });
  },

  setOrderAsDelivered(id: number) {
    return makeOrderPatchRequest(id, { isDelivered: true });
  },

  setOrderAsReturned(id: number) {
    return makeOrderPatchRequest(id, { isReturned: true });
  },

  updateValuation(id: number, valuation: number) {
    return makeOrderPatchRequest(id, { valuation });
  },
};
