import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  NgZone,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements AfterViewInit, OnDestroy {
  @ViewChild('content')
  public contentElementRef: ElementRef;
  @Input()
  public input: HTMLElement;

  public inputHasFocus: boolean;
  public activeOptionIndex: number;
  private _inputBlurTimeout;

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
  ) { }

  ngAfterViewInit() {
    this.input.addEventListener('focus', this.onInputFocus.bind(this));
    this.input.addEventListener('blur', this.onInputBlur.bind(this));
    this.input.addEventListener('keydown', this.onInputKeypress.bind(this));
  }

  ngOnDestroy() {
    this.input.removeEventListener('focus', this.onInputFocus.bind(this));
    this.input.removeEventListener('blur', this.onInputBlur.bind(this));
    this.input.removeEventListener('keydown', this.onInputKeypress.bind(this));
  }

  private onInputFocus(event) {
    if (!window.getSelection().toString() && event.srcElement.value) {
      // Required for mobile Safari
      event.srcElement.setSelectionRange(0, event.srcElement.value.length);
    }
    clearTimeout(this._inputBlurTimeout);
    this._inputBlurTimeout = null;

    if (!this.inputHasFocus) {
      this.ngZone.run(() => {
        this.inputHasFocus = true;
      });
    }
  }

  private onInputBlur() {
    if (!this._inputBlurTimeout && this.inputHasFocus) {
      this._inputBlurTimeout = setTimeout(() => {
        this.ngZone.run(() => {
          this.inputHasFocus = false;
          this.activeOptionIndex = -1;
          this._inputBlurTimeout = null;
          this.cdr.detectChanges();
        });
      }, 200);
    }
  }

  private onInputKeypress(e) {
    const key = e.keyCode;

    if (!this.contentElementRef) {
      return;
    }

    const optionList = this.contentElementRef.nativeElement.querySelectorAll('.mat-option');

    const selectOptionByIndex = (index?: number) => {
      optionList.forEach((option) => {
        option.classList.remove('mat-active');
      });

      if (index > -1 && optionList[index]) {
        optionList[index].classList.add('mat-active');
      }
    };

    // Escape key to close the autocomplete
    if (key === 27) {
      this.ngZone.run(() => {
        this.input.blur();
      });
    }

    // Down arrow to navigate down
    if (key === 40) {
      this.ngZone.run(() => {
        this.activeOptionIndex > optionList.length - 2
          ? (this.activeOptionIndex = 0)
          : this.activeOptionIndex++;
        selectOptionByIndex(this.activeOptionIndex);
      });
    }

    // Up arrow to navigate up
    if (key === 38) {
      this.ngZone.run(() => {
        this.activeOptionIndex === 0
          ? (this.activeOptionIndex = optionList.length - 1)
          : this.activeOptionIndex--;
        selectOptionByIndex(this.activeOptionIndex);
      });
    }

    // Return key to select
    if (key === 13) {
      this.ngZone.run(() => {
        if (this.activeOptionIndex > -1 && this.contentElementRef) {
          const selectedOption = this.contentElementRef.nativeElement.querySelector('.mat-active');
          selectedOption.click();
          this.input.blur();
          this.inputHasFocus = false;
        }
      });
    }

    // Any key other than the arrows reset the list
    if (key !== 40 && key !== 38) {
      this.ngZone.run(() => {
        this.activeOptionIndex = -1;
        selectOptionByIndex();
      });
    }
  }
}
