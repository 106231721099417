import { getAll } from '../create-action';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { fetchCategory } from './fetchCategory';

@Injectable()
export class CategoriesActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchCategories = getAll(this.ngRedux.dispatch, '/v2/categories', 'categories');


  fetchCategory = fetchCategory(this.ngRedux.dispatch);

}
