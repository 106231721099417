import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

export { bootstrapAPI } from './bootstrapAPI';
export { categoriesAPI } from './categoriesAPI';
export { searchAPI } from './searchAPI';
export { productAPI } from './productAPI';
export { orderAPI } from './orderAPI';
export { reviewsAPI } from './reviewsAPI';
export { factsAPI } from './factsAPI';
export { couponAPI } from './couponAPI';
export { promoCodeAPI } from './promoCodeAPI';
export { campaignSignupAPI } from './campaignSignupAPI';
export { locationAreaAPI } from './locationAreaAPI';
export { topAPI } from './topAPI';
export { usersAPI } from './usersAPI';
export { paymentsAPI } from './paymentsAPI';
export { checkoutAPI } from './checkoutAPI';

import { WebApi } from '@hygglo/web-api';

const webApi = new WebApi(request, getAPIURI.getCurrentBase);

export const { myUserAPI, npsAPI, productListingAPI } = webApi;
