import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

class PublicPasswordResetAPI {
  async initiatePasswordReset(email: string) {
    const uri = getAPIURI.initiatePasswordReset();

    const response = await request({
      uri,
      json: { email },
      method: 'POST',
    });

    return response.body;
  }

  async completePasswordReset(token: string, newPassword: string, confirmPassword: string) {
    const uri = getAPIURI.completePasswordReset(token);
    const response = await request({
      uri,
      json: { newPassword, confirmPassword },
      method: 'PUT',
    });

    return response.body;
  }
}

export default new PublicPasswordResetAPI();
