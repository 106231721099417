import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const order = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.ORDER_FETCH_INIT:
    case actionTypes.ORDER_FETCH_FAIL:
      return state.setIn([action.data.get('id'), 'status'], action.data.get('status'));
    case actionTypes.ORDER_FETCH_SUCCESS:
      const newState = state.setIn([action.data.get('id'), 'status'], action.data.get('status'));
      return newState.setIn([action.data.get('id'), 'response'], action.data.getIn(['response']));
    case actionTypes.ORDER_ADD_MSG:
      const orderId = action.data.get('id');
      const now = new Date().toISOString();
      const activities = state.getIn([orderId, 'response', 'activities']).push(
        fromJS({
          type: 'message',
          byMe: true,
          text: action.data.get('msg'),
          createdOnUtc: now,
          isPending: true
        })
      );
      return state.setIn([orderId, 'response', 'activities'], activities);
    case actionTypes.ORDER_ARCHIVE:
      return state.getIn([action.data.get('id'), 'response'])
        ? state.setIn([action.data.get('id'), 'response', 'isArchived'], true)
        : state;
    case actionTypes.ORDER_SAVE_DRAFT:
      return state.setIn([action.data.get('id'), 'draft'], action.data.get('draft'));
    case actionTypes.CLEAR_STATE:
      return initialState;
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'order']) || fromJS({});

    default:
      return state;
  }
};

export default order;
