import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';

@Injectable()
export class NewProductActions {
  constructor(private ngRedux: NgRedux<any>) {}

  addNewProductImage(data) {
    this.ngRedux.dispatch({
      data: fromJS(data),
      type: actionTypes.NEWPRODUCT_ADD_IMAGE
    });
  }

  clearNewProduct() {
    this.ngRedux.dispatch({
      data: fromJS({}),
      type: actionTypes.CLEAR_NEW_PRODUCT_STATE
    });
  }

  deleteNewProductImage(data) {
    this.ngRedux.dispatch({
      data: fromJS(data),
      type: actionTypes.NEWPRODUCT_DELETE_IMAGE
    });
  }

  updateNewProductInfo(data) {
    this.ngRedux.dispatch({
      data: fromJS(data),
      type: actionTypes.NEWPRODUCT_UPDATE_INFO
    });
  }
}
