import {
  Component,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { LocationService, Location } from '../../services/location.service';
import { FilteredCategoryType } from '@hygglo/categories';
import { WebCategoryService } from 'src/app/services/web-category.service';
import { searchAPI } from 'src/webapi/v2';
import { fromJS } from 'immutable';
import debounce from 'lodash-es/debounce';

type AutoCompleteSuggestion = {
  suggestion: string;
};

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent {
  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef;
  public unsubscribe: any;
  public query: any;

  public filteredCategoryList: Array<FilteredCategoryType>;
  public autoCompleteSuggestions: Array<AutoCompleteSuggestion>;

  constructor(
    public router: Router,
    public angulartics2: Angulartics2,
    public locationService: LocationService,
    public categoryService: WebCategoryService,
    private cdr: ChangeDetectorRef
  ) {}

  public go() {
    if (this.filteredCategoryList && this.filteredCategoryList.length > 0) {
      const categorySlug = this.filteredCategoryList[0].slug;
      this.router.navigate(['/category', categorySlug]);
      this.searchInput.nativeElement.blur();
    } else {
      this.search(this.query);
    }
    this.cdr.detectChanges();
  }

  public search(query: string) {
    if (query) {
      this.query = query;
      this.router.navigate(['/search', query]);
      this.searchInput.nativeElement.blur();
      this.cdr.detectChanges();
    }
  }

  async inputChanged(value: string) {
    const query = (value || '').trim();
    await Promise.all([this.filterCategories(query), this.autoComplete(query)]);
  }

  async filterCategories(query: string) {
    this.categoryService.verifyLoaded();
    if (query.length <= 1) {
      this.filteredCategoryList = [];
    } else {
      this.filteredCategoryList = await this.categoryService.filterCategoriesAsync(query);
    }
  }

  autoComplete = debounce(async (query: string) => {
    if (query.length <= 2) {
      this.autoCompleteSuggestions = [];
    } else {
      this.autoCompleteSuggestions = (await searchAPI.fetchAutoCompleteSuggestions(
        query
      )) as Array<AutoCompleteSuggestion>;
    }
    this.cdr.detectChanges();
  }, 100);

  public searchSuggestionSelected(category: FilteredCategoryType) {
    const currentlocation = this.locationService.getLocation();

    this.filteredCategoryList = [];

    this.query = category && category.name;

    const categorySlug = category.slug;
    this.angulartics2.eventTrack.next({
      action: 'Search Category Selected',
      properties: {
        label: categorySlug + '|' + currentlocation.latitude + '|' + currentlocation.longitude,
        category: 'Search',
      },
    });

    this.router.navigate(['/category', categorySlug]);
    this.searchInput.nativeElement.blur();
    this.cdr.detectChanges();
  }
}
