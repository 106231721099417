import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
// import { captureMessage, Severity } from '@sentry/browser';
import { environment } from '../environments/environment';

export class HyggloMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const msg = `Translation missing for key: ${params.key} (${
      params.translateService.currentLang
    })`;
    if (!environment.production) {
      console.warn(msg);
    } else {
      // captureMessage(msg, Severity.Error);
    }
    return '% Translation missing %';
  }
}
