import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { privateNotificationsAPI } from '../../webapi/private/index';
import { Dispatch } from 'redux';

export const consumeNotifications = (dispatch: Dispatch<any>) => {
  const setLoadingState = () => {
    return {
      data: fromJS({
        status: 'LOADING',
      }),
      type: actionTypes.NOTIFICATIONS_FETCH_INIT,
    };
  };

  const setErrorState = (error) => {
    return {
      data: fromJS({
        status: 'ERROR',
      }),
      type: actionTypes.NOTIFICATIONS_FETCH_FAIL,
    };
  };

  const setSuccessState = (response) => {
    return {
      data: fromJS({
        response: response,
        status: 'SUCCESS',
      }),
      type: actionTypes.NOTIFICATIONS_FETCH_SUCCESS,
    };
  };

  return (referenceId: string, notificationType?: string) => {
    dispatch(setLoadingState());
    privateNotificationsAPI
      .markAsConsumed(referenceId, notificationType)
      .then((response): any => dispatch(setSuccessState(response)))
      .catch((err): any => dispatch(setErrorState(err)));
  };
};
