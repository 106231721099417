import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { privateNotificationsAPI } from '../../webapi/private/index';
import { Dispatch } from 'redux';

const setSuccessState = (response) => {
  return {
    data: fromJS({
      response: response,
      status: 'SUCCESS'
    }),
    type: actionTypes.NOTIFICATIONS_FETCH_SUCCESS
  };
};

export const fetchNotifications = (dispatch: Dispatch<any>) => {
  const setLoadingState = () => {
    return {
      data: fromJS({
        status: 'LOADING'
      }),
      type: actionTypes.NOTIFICATIONS_FETCH_INIT
    };
  };

  const setErrorState = (error) => {
    return {
      data: fromJS({
        status: 'ERROR'
      }),
      type: actionTypes.NOTIFICATIONS_FETCH_FAIL
    };
  };

  return () => {
    dispatch(setLoadingState());
    privateNotificationsAPI
      .fetchNotifications()
      .then((response): any => dispatch(setSuccessState(response)))
      .catch((err): any => dispatch(setErrorState(err)));
  };
};

export const setNewNotificationsState = (ngRedux) => (message: any) => {
  const currentNotifications = ngRedux.getState().getIn(['notifications', 'response']);
  ngRedux.dispatch(setSuccessState([...currentNotifications.toJS(), message.data]));
};
