import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { searchAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';
import { SearchOptsType } from '../../webapi/v2/searchAPI';

const setLoadingState = () => {
  return {
    data: fromJS({
      response: [],
      status: 'LOADING'
    }),
    type: actionTypes.SEARCH_FETCH_INIT
  };
};

const setErrorState = (error) => {
  return {
    data: fromJS({
      response: error,
      status: 'ERROR'
    }),
    type: actionTypes.SEARCH_FETCH_FAIL
  };
};

const setSuccessState = (response) => {
  return {
    data: fromJS({
      response: response,
      status: 'SUCCESS'
    }),
    type: actionTypes.SEARCH_FETCH_SUCCESS
  };
};

export const fetchSearchResult = (dispatch: Dispatch<any>) => async (opts: SearchOptsType) => {
  dispatch(setLoadingState());

  try {
    const response = await searchAPI.fetchSearchResult(opts);
    return dispatch(setSuccessState(response));
  } catch (err) {
    return dispatch(setErrorState(err));
  }
};
