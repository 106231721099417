import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const search = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.SEARCH_FETCH_INIT:
    case actionTypes.SEARCH_FETCH_FAIL:
      const status = action.data.get('status');
      return state.setIn(['status'], status);
    case actionTypes.SEARCH_FETCH_SUCCESS:
      return action.data;

    case actionTypes.PRODUCT_SET_FAVORITE:
      const listingId = parseInt(action.data.get('id'), 10);
      const isFavorite = action.data.get('isFavorite');
      const listings = state.getIn(['response', 'productListings']);

      if (!listings) {
        return state;
      }

      return state.setIn(
        ['response', 'productListings'],
        listings.map((listing) =>
          listing.get('id') === listingId ? listing.set('isFavorite', isFavorite) : listing
        )
      );

    case actionTypes.SEARCH_CLEAR:
    case actionTypes.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default search;
