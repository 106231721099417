import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { onVisible } from '../../../utils/onVisible';

@Component({
  selector: 'fancy-img',
  templateUrl: './fancy.img.component.html',
  styleUrls: ['./fancy.img.component.scss']
})
export class FancyImg implements OnInit, OnDestroy {
  @Input()
  public src: string;

  @ViewChild('visibility', { static: true })
  private visibilityRef: ElementRef;

  public loadImage = false;
  private disconnect: () => void;

  public preloadSrc: string;

  constructor(private ref: ChangeDetectorRef) {}

  private setPreloadSrc() {
    if (this.src.includes('imgix')) {
      this.preloadSrc = `${this.src.replace(/q=\d+/, 'q=35')}&blur=1000`;
    }
  }

  public ngOnInit(): void {
    this.disconnect = onVisible(
      this.visibilityRef.nativeElement,
      () => {
        this.loadImage = true;
        this.ref.detectChanges();
      },
      100
    );
    // this.setPreloadSrc();
  }

  public ngOnDestroy(): void {
    this.disconnect();
    this.ref.detach();
  }
}
