import { Component } from '@angular/core';
import { AppConfigService } from '../../services/app-config.service';
import { HyggloTranslateService, LANGUAGE } from '../../services/translate.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CrispArticleType } from 'src/app/services/crisp-enum';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public currentLang: string;
  public email: string;
  public orgno: string;
  public address: string;
  public environment: any;

  constructor(
    public translate: HyggloTranslateService,
    public configService: AppConfigService,
    public router: Router
  ) {
    this.environment = environment;
    translate.onLangChange.subscribe((newLang: string) => {
      this.currentLang = newLang;
    });
    this.currentLang = translate.getLanguage();
    this.email = configService.config.email;
    this.orgno = configService.config.orgnumber;
    this.address = configService.config.address;
  }

  public changeLanguage(langCode: string) {
    this.translate.setLanguage(langCode as LANGUAGE);
    return true;
  }

  public openFaq(oldArticleId: string) {
    if (oldArticleId == '5223009-general-insurance-when-renting-out') {
      this.translate.openFaq(CrispArticleType.INSURANCE_RENTER);
    }
  }

  public openBlog() {
    if (this.configService.country === 'NO') {
      window.location.href = 'https://blogg.hygglo.no';
    } else if (this.configService.country === 'FI') {
      window.location.href = 'https://blog.hygglo.fi';
    } else {
      this.router.navigate(['/blog']);
    }
  }
}
