import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const reviewsAPI = {
  async fetchReviewsByUserId(ownerId: number) {
    const uri = buildUrl('/v2/product-reviews', {
      ownerId,
      $limit: 100,
    });

    const options = {
      uri,
      responseType: 'json',
    };
    const response = await request(options, true);
    return response.body;
  },
  async getLastTopReviews() {
    const uri = buildUrl('/v2/product-reviews', {
      rating: 5,
      $limit: 50,
    });
    const options = { uri, method: 'GET' };

    const { body } = await request(options, true);
    const json = JSON.parse(body);

    const country = localStorage.getItem('country');

    const reviews = json.data
      .filter((review) => review.text !== null)
      .filter((review_, index: number) => index < 20);

    return reviews;
  },
};
