import { Component, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import storage from '../utils/storage';
import { Location } from '@angular/common';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { Angulartics2HyggloTracking } from './services/event.tracking';
import { Crisp } from 'crisp-sdk-web';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

// Redux Store
import { NgRedux } from '@angular-redux/store';

// Events
import eventTypes from '../types/eventTypes';
import EventEmitter from '../utils/EventEmitter';

// Components
import { privateAccountAPI } from '../webapi/private';
import getTokens from '../utils/getTokens';
import { SocketService } from './services/socket.service';
import { AccountActions } from '../actions/account';
import { StorageActions } from '../actions/storage';
import { NotificationsActions } from '../actions/notifications';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { onVisible } from 'src/utils/onVisible';
import { HyggloTranslateService } from './services/translate.service';
import { AlertService } from './services/alert/alert.service';
import { CrispArticleType } from './services/crisp-enum';

declare const window: any;
const ee = new EventEmitter();

//const POLL_ID = `${Date.now()}`.slice(-3);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('footer', { static: false })
  public visibilityRef: ElementRef;
  public showFooter: boolean = false;

  public hasProfileData = false;
  public notificationCount: number;
  public profileImage: string;
  public firstName: string;
  public notificationPollingTimer: any;
  public isLoginOpen = false;
  public registerModalOpen: boolean = false;
  public balance: number = 0;
  public isVendor: boolean;
  public slug: string;
  public profileProblems: number = 0;

  // busy spinner
  public loaderIsShowing: any;
  public loaderShowDelay: any;
  public spinnerCalls: number;

  public loaded: boolean = false;

  constructor(
    public ngRedux: NgRedux<any>,
    public router: Router,
    public angulartics2GoogleAnalytics: Angulartics2GoogleGlobalSiteTag,
    public angulartics2HyggloTracking: Angulartics2HyggloTracking,
    public alertService: AlertService,
    public angulartics2: Angulartics2,
    public socketService: SocketService,
    public accountActions: AccountActions,
    public storageActions: StorageActions,
    public notificationActions: NotificationsActions,
    public translate: TranslateService,
    public hyggloTranslate: HyggloTranslateService,
    public titleService: Title,
    public urlLocation: Location
  ) {
    urlLocation.onUrlChange((url, state) => {
      this.loaded = true;
    });
    ngRedux
      .select(['notifications', 'response'])
      .pipe(filter((value) => !!value))
      .pipe(distinctUntilChanged())
      .pipe(map((v: any) => v.toJS()))
      .subscribe((result: any) => {
        this.notificationCount = result.length;
        this.updateTabNotificationNumber();
      });

    ngRedux
      .select(['account', 'response'])
      .pipe(filter((value) => !!value))
      .pipe(distinctUntilChanged())
      .subscribe((result: any) => {
        const hasProfileData = !!result;
        const isVendor = result.getIn(['customer', 'isLessor']);
        const slug = result.getIn(['vendor', 'slug']);
        const firstName = result.getIn(['customer', 'firstName']);
        const email = result.getIn(['customer', 'email']);
        const hmac = result.getIn(['customer', 'hmacCrisp']);
        console.log(hmac);
        const balance = result.getIn(['customer', 'balance']);

        if (isVendor) {
          this.profileProblems = result.getIn(['vendor', 'profileProblems']);
        }
        const profileImage = result.getIn(['customer', 'profileImage', 'fullSizeUrl']);

        // happens first time when user is logged in
        if (!this.hasProfileData && hasProfileData) {
          this.delayedCrispBoot(
            {
              appId: environment.crisp_website_id,
              email: email,
              user_hash: hmac,
            },
            2000
          );
          const tokens = getTokens();
          if (tokens) {
            this.initializeSocket(tokens);
          } else {
            this.socketService.logout();
          }
          this.startNotificationsPolling();
        }

        // happens first time after user is logged out when was logged in before
        if (this.hasProfileData && !hasProfileData) {
          this.delayedCrispBoot({ appId: environment.crisp_website_id }, 2000);
          this.socketService.logout();
        }

        this.slug = slug;
        this.hasProfileData = hasProfileData;
        this.firstName = firstName;
        this.profileImage = profileImage;
        this.balance = balance;
        this.isVendor = isVendor;
      });

    this.angulartics2 = angulartics2;

    angulartics2GoogleAnalytics.startTracking();

    this.checkLocalStorage();

    storageActions.fetchPersistentStorage();

    this.setUpGenericErrorModal();
    this.setUpGlobalRegisterModal();
    this.setUpGlobalLoginModal();
    this.setupGlobalEmailLoginFailModal();
    this.setUpBusySpinner();
    this.loginIfTokens();

    ee.events.on(eventTypes.LOGOUT, () => {
      this.logout();
    });
  }

  public async loginIfTokens() {
    const tokens = localStorage.getItem('tokens');
    if (tokens) {
      const response = await privateAccountAPI.fetchAccount();
      this.accountActions.updateAccount({ response });
    }
  }

  public initializeSocket(tokens) {
    this.socketService.connect();
    this.socketService.socket.login({ token: tokens.access_token });

    this.socketService.socket.on('GET_MESSAGE', (data) => {
      this.notificationActions.setNewNotificationsState(data);
    });
  }

  ngAfterViewInit() {
    this.initializeFooter();
    // run this first w no user data, overwritten below if user is logged in
    this.delayedCrispBoot({ appId: environment.crisp_website_id }, 2000);
  }

  ngOnDestroy() {
    this.stopNotificationsPolling();
  }

  delayedCrispBoot(settings, timeOut) {
    setTimeout(() => {
      Crisp.configure(settings.appId);
      Crisp.user.setEmail(settings.email, settings.user_hash);
    }, timeOut);
  }

  updateTabNotificationNumber() {
    const currentTitle = this.titleService.getTitle();
    const regex = /\((.*?)\)/g;
    const removeCurrentBadge = currentTitle.replace(regex, '');
    const label =
      this.notificationCount > 0
        ? `(${this.notificationCount}) ${removeCurrentBadge}`
        : removeCurrentBadge;
    this.titleService.setTitle(label);
  }

  setUpGenericErrorModal() {
    ee.events.on(eventTypes.GENERIC_ERROR, (data) => {
      this.alertService.alert(data.msg, data.title || this.translate.instant('APP.ERROR'));
    });
  }

  public isMedium() {
    return window.innerWidth > 700;
  }

  public setUpGlobalRegisterModal(): void {
    ee.events.on(eventTypes.ACCOUNT_REGISTER_REQUESTED, (loginData) => {
      this.registerModalOpen = true;
      this.router.navigate([{ outlets: { modal: ['user', 'register'] } }], {
        state: loginData,
        skipLocationChange: true,
      });
    });
    ee.events.on(eventTypes.ACCOUNT_REGISTER_FINISHED, () => {
      this.registerModalOpen = false;
    });
  }

  initializeFooter() {
    if (this.visibilityRef) {
      onVisible(this.visibilityRef.nativeElement, () => (this.showFooter = true), 10);
    }
  }

  checkLocalStorage() {
    if (!storage.isAvailable()) {
      const alertData = {
        title: this.translate.instant('APP.TITLE'),
        message: this.translate.instant('APP.MSG'),
        accept: this.translate.instant('APP.ACCEPT'),
      };
      this.alertService.alert(alertData.message, alertData.title);
      this.angulartics2.eventTrack.next({
        action: 'Local storage not available',
        properties: {
          category: 'Other',
        },
      });
    }
  }

  setUpGlobalLoginModal() {
    ee.events.on(eventTypes.ACCOUNT_LOGIN_REQUESTED, (intent) => {
      if (!this.isLoginOpen && !this.registerModalOpen) {
        this.isLoginOpen = true;
        this.router.navigate([{ outlets: { modal: ['user', 'login'] } }], {
          queryParams: { intent },
          skipLocationChange: true,
        });
      }
    });
    ee.events.on(eventTypes.ACCOUNT_LOGIN_CLOSED, (intent) => {
      this.isLoginOpen = false;
    });
  }

  setupGlobalEmailLoginFailModal() {
    ee.events.on(eventTypes.EMAIL_LOGIN_FAIL, (data) => {
      const error = data.body && data.body.error;
      if (error?.messagesToUser) {
        const title = error.messagesToUser.title;
        const msg = error.messagesToUser.msg;
        this.alertService.alert(msg, title);
      } else {
        this.translate.get(['LOGIN.FAIL.TITLE', 'LOGIN.FAIL.MSG']).subscribe((res: Object) => {
          const title = res['LOGIN.FAIL.TITLE'];
          const msg = res['LOGIN.FAIL.MSG'];
          this.alertService.alert(msg, title);
        });
      }
    });
  }

  setUpBusySpinner() {
    this.loaderIsShowing = false;
    this.spinnerCalls = 0;

    ee.events.on(eventTypes.APP_BUSY, () => {
      if (this.spinnerCalls === 0) {
        if (!this.loaderIsShowing) {
          this.loaderShowDelay = setTimeout(() => {
            this.loaderIsShowing = true;
          }, 200);
        }
      }
      this.spinnerCalls = this.spinnerCalls + 1;
    });

    ee.events.on(eventTypes.APP_NOT_BUSY, () => {
      this.spinnerCalls = this.spinnerCalls - 1;

      if (this.spinnerCalls === 0) {
        clearTimeout(this.loaderShowDelay);
        if (this.loaderIsShowing) {
          this.loaderIsShowing = false;
        }
      }
    });
  }

  getNotifications() {
    storage
      .get('tokens')
      .then((response: any) => {
        const tokens = (() => {
          try {
            return JSON.parse(response);
          } catch (err) {
            return {};
          }
        })();

        if (tokens.access_token && tokens.refresh_token && this.hasProfileData) {
          this.notificationActions.fetchNotifications();
        } else {
          this.notificationActions.clearNotificationsState();
          this.stopNotificationsPolling();
        }
      })
      .catch((): any => {
        /*Do nothing*/
      });
  }

  startNotificationsPolling() {
    this.getNotifications();
    this.notificationPollingTimer = setInterval(() => {
      this.getNotifications();
    }, 60 * 5000); // every 5 minutes, as backup for websockets
  }

  stopNotificationsPolling() {
    clearInterval(this.notificationPollingTimer);
  }

  login() {
    privateAccountAPI.fetchAccount().catch(() => {
      // Resulting error is handled elsewhere
    });
  }

  public openFaq(oldArticleId: string) {
    if (oldArticleId == '5203904-how-hygglo-works-in-short') {
      this.hyggloTranslate.openFaq(CrispArticleType.HOW_IT_WORKS_IN_SHORT);
    } else if (oldArticleId == '5224681-how-does-the-insurance-work-when-i-rent-something') {
      this.hyggloTranslate.openFaq(CrispArticleType.INSURANCE_RENTER);
    }
  }

  public logout() {
    const afterLogout = () => {
      // we need to reboot intercom to make sure that local intercom user data is removed after logging out
      Crisp.session.reset();
      storage
        .remove('intercom-state')
        .catch((...args): any => {
          /*Do nothing*/
        })
        .then(() => {
          // this.intercom.boot();
        });
      storage.remove('data').catch((): any => {
        /*Do nothing*/
      });
      storage.remove('tokens').catch((): any => {
        /*Do nothing*/
      });
      location.href = '/';
    };
    this.socketService.logout();
    privateAccountAPI
      .logOut()
      .then(() => {
        afterLogout();
      })
      .catch(() => {
        afterLogout();
      });
  }
}
