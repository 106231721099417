import immutable from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = immutable.fromJS({});

const counts = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.ORDERS_COUNT_INIT:
    case actionTypes.ORDERS_COUNT_FAIL:
      return state.set('status', action.data.get('status'));

    case actionTypes.ORDERS_COUNT_SUCCESS:
      return action.data;

    case actionTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default counts;
