import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { privateOrderAPI } from '../../webapi/private/index';
import { Dispatch } from 'redux';

const defaultSortOrder = (orderFilter) => {
  switch (orderFilter) {
    case 'future': // vendor
      return 'order-start-date';
    case 'active': // customer
      return 'order-start-date';
    case 'completed':
      return 'order-end-date';
    default:
      return 'latest-activity';
  }
};

export const fetchOrders =
  (dispatch: Dispatch<any>) =>
  (
    role: string,
    filter: string,
    offset: number,
    limit: number,
    silent?: boolean,
    sortOrder?: string
  ) => {
    const setLoadingState = () => {
      return {
        data: fromJS({ status: 'LOADING' }),
        type: actionTypes.ORDERS_FETCH_INIT,
      };
    };

    const setErrorState = (error) => {
      return {
        data: fromJS({ status: 'ERROR' }),
        type: actionTypes.ORDERS_FETCH_FAIL,
      };
    };

    const setSuccessState = (response) => {
      return {
        data: fromJS({ response: response, status: 'SUCCESS' }),
        type: actionTypes.ORDERS_FETCH_SUCCESS,
      };
    };

    dispatch(setLoadingState());
    const sort = sortOrder || defaultSortOrder(filter);
    privateOrderAPI
      .fetchOrders(role, filter, sort, offset, limit, silent)
      .then((response): any => dispatch(setSuccessState(response)))
      .catch((err): any => dispatch(setErrorState(err)));
  };
