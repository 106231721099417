import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { Dispatch } from 'redux';

export const saveOrderMsgDraft = (dispatch: Dispatch<any>) => (id: any, draft: any) => {
  return dispatch({
    data: fromJS({
      id,
      draft
    }),
    type: actionTypes.ORDER_SAVE_DRAFT
  });
};
