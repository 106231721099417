import { fetchProduct } from './fetchProduct';
import { fetchRelatedProducts } from './fetchRelatedProducts';
import { setAsFavorite } from './setAsFavorite';
import { removeAsFavorite } from './removeAsFavorite';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

@Injectable()
export class ProductActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchProduct = fetchProduct(this.ngRedux.dispatch);

  fetchRelatedProducts = fetchRelatedProducts(this.ngRedux.dispatch);

  setAsFavorite = setAsFavorite(this.ngRedux.dispatch);

  removeAsFavorite = removeAsFavorite(this.ngRedux.dispatch);
}
