import actionTypes from '../../types/actionTypes';
import immutable from 'immutable';
import { Dispatch } from 'redux';

const clearDataState = () => {
  return {
    data: immutable.fromJS({}),
    type: actionTypes.ORDERS_CLEAR,
  };
};

export const clearOrders = (dispatch: Dispatch<any>) => () => {
  dispatch(clearDataState());
};
