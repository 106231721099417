import getAPIURI from '../getAPIURI';
import request from '../../utils/request';

const publicProductAPI = {

  /* eslint-disable  */
  fetchProduct(id:string, silent?:boolean) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.fetchProductById(id);
      const options = {
        responseType: 'json',
        uri,
        simple: true,
      };
      request(options, silent)
        .then((response:any):any => resolve(response.body))
        .catch((err):any => reject(err));
    });
  },

  /* eslint-disable */
  reportAbuse(productId:string, message:string) {
    return new Promise((resolve, reject) => {
      const uri = getAPIURI.reportAbuse();
      const options = {
        uri,
        json: {
          message,
          productId,
        },
        method: 'POST',
        responseType: '',
      };
      request(options)
        .then((response:any):any => resolve(response.body))
        .catch((err):any => reject(err));
    });
  },

};

export default publicProductAPI;
