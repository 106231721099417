import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const notifications = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.STORAGE_FETCH_SUCCESS:
      return action.data.getIn(['response', 'notifications']) || fromJS({});
    case actionTypes.NOTIFICATIONS_FETCH_INIT:
    case actionTypes.NOTIFICATIONS_FETCH_SUCCESS:
    case actionTypes.NOTIFICATIONS_FETCH_FAIL:
      return state.merge(action.data);
    case actionTypes.CLEAR_NOTIFICATIONS_STATE:
      return initialState;
    case actionTypes.CLEAR_STATE:
      return initialState;

    default:
      return state;
  }
};

export default notifications;
