import { fromJS } from 'immutable';
import { publicCrudAPI } from '../webapi/public';
import * as typeGenerator from '../types/typeGenerator';
import { environment } from '../environments/environment';

export const getAll = (dispatch, path, name: string) => {
  // Success
  const setSuccessState = (response) => {
    return {
      data: fromJS({
        response: response,
        status: 'SUCCESS'
      }),
      type: typeGenerator.success(name)
    };
  };

  // Error.
  const setErrorState = (error) => {
    return {
      data: fromJS({
        response: error,
        status: `ERROR: ${name}`
      }),
      type: typeGenerator.error(name)
    };
  };

  const setLoadingState = () => {
    return {
      data: fromJS({
        response: [],
        status: 'LOADING'
      }),
      type: typeGenerator.loading(name)
    };
  };

  return async (silent = true) => {
    const uri = `${environment.api_url}${path}`;

    dispatch(setLoadingState());
    const response = await publicCrudAPI.fetchAll(uri, silent);
    dispatch(setSuccessState(response));
    return response;
  };
};
