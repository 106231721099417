import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const bootstrapAPI = {
  fetchBootstrap() {
    return new Promise((resolve, _reject) => {
      const uri = buildUrl('/v2/bootstrap');
      const options = {
        uri,
        responseType: 'json',
        simple: true
      };
      const fetchRequest = () =>
        request(options, true)
          .then(
            (response: any): any => {
              resolve(response.body);
            }
          )
          .catch(
            (err): any => {
              setTimeout(() => {
                fetchRequest();
              }, 1000);
            }
          );
      fetchRequest();
    });
  }
};
