import debounce from 'lodash-es/debounce';
import Event from 'minivents';

const setupPolyfill = () => {
  const ee = new Event();
  const eventListener = debounce(() => ee.emit('change'), 50);

  window.addEventListener('scroll', eventListener);
  window.addEventListener('resize', eventListener);
  const domObserver = new MutationObserver(eventListener);
  domObserver.observe(document, {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true
  });

  return (element: Element, cb: () => void, size: Number = 300): (() => void) => {
    const disconnect = () => {
      ee.off('change', onChange);
    };

    const onChange = () => {
      const { top, bottom, left, right } = element.getBoundingClientRect();

      if (
        top > -size &&
        window.innerHeight - bottom > -size &&
        left > -size &&
        window.innerWidth - right > -size
      ) {
        cb();
        disconnect();
      }
    };

    ee.on('change', onChange);

    onChange();

    return disconnect;
  };
};
const setupNative = () => (element: Element, cb: () => void, size: Number = 300): (() => void) => {
  const margin = `${size}px`;
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        cb();
        observer.disconnect();
      }
    },
    { root: null, rootMargin: margin }
  );

  observer.observe(element);

  return () => {
    observer.disconnect();
  };
};

export const onVisible =
  typeof IntersectionObserver !== 'undefined' ? setupNative() : setupPolyfill();
