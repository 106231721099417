import { combineReducers } from 'redux-immutable';

import bootstrap from './bootstrap';
import account from './account';
import product from './product';
import order from './order';
import counts from './counts';
import orders from './orders';
import myProducts from './myProducts';
import myProduct from './myProduct';
import newProduct from './newProduct';
import newAccount from './newAccount';
import search from './search';
import settings from './settings';
import notifications from './notifications';
import categories from './categories';
import favorites from './favorites';
import users from './users';
import topCategories from './topCategories';
import reviews from './reviews';
import paidOutOrders from './paidOutOrders';

const rootReducer = combineReducers({
  bootstrap,
  account,
  order,
  counts,
  orders,
  product,
  search,
  myProducts,
  myProduct,
  newProduct,
  newAccount,
  settings,
  notifications,
  categories,
  favorites,
  users,
  topCategories,
  reviews,
  paidOutOrders,
});

export default rootReducer;
