import { buildUrl } from '../getAPIURI';
import request, { getJson } from '../../utils/request';

export interface QueryLocation {
  name: string;
  uniqueName?: string;
  slug: string;
  country: string;
  preposition: string;
  lon: number;
  lat: number;
  numberOfVendors: number;
  numberOfCustomers: number;
  numberOfProducts: number;
  parent?: {
    name: string;
    slug: string;
  };
  parentName?: string;
  topAdsCategories?: any;
  topSoldCategories?: any;
  topSearchedCategories?: any;
  latestOrderProductListings?: any;
  latestProductReviews?: any;
  averageProductRating?: number;
  numberOfProductReviews?: number;
}

export type CompactLocation = Pick<
  QueryLocation,
  'name' | 'slug' | 'preposition' | 'numberOfProducts' | 'parentName'
>;

export const locationAreaAPI = {
  async fetchLocation(opts: {
    categoryId?: number;
    slug: string;
    statsOnly?: boolean;
  }): Promise<QueryLocation | null> {
    const uri = buildUrl(`/v2/location-areas/${opts.slug}`, {
      categoryId: opts.categoryId,
      statsOnly: opts.statsOnly,
    });
    const options = {
      uri,
      responseType: 'json',
      simple: true,
    };

    const { body } = await request(options, true);

    if (!body) {
      return null;
    }

    const {
      name,
      uniqueName,
      country,
      slug,
      preposition,
      position,
      numberOfVendors,
      numberOfCustomers,
      numberOfProducts,
      parentName,
      parent,
      topAdsCategories,
      topSoldCategories,
      latestOrderProductListings,
      topSearchedCategories,
      latestProductReviews,
      averageProductRating,
      numberOfProductReviews,
    } = body;
    const [lon, lat] = position.coordinates;

    return {
      name,
      uniqueName,
      country,
      slug,
      preposition,
      numberOfVendors,
      numberOfCustomers,
      numberOfProducts,
      parentName,
      parent,
      lon,
      lat,
      topAdsCategories,
      topSoldCategories,
      latestOrderProductListings,
      topSearchedCategories,
      latestProductReviews,
      averageProductRating,
      numberOfProductReviews,
    };
  },

  async searchForLocation(query: string): Promise<CompactLocation[]> {
    const { body: searchResults } = await getJson({
      path: `/v2/location-areas?q=${encodeURIComponent(query)}`,
    });
    return searchResults;
  },

  async findByPosition({
    longitude,
    latitude,
  }: {
    longitude: number;
    latitude: number;
  }): Promise<CompactLocation[]> {
    const { body: locations } = await getJson({
      path: `/v2/location-areas?longitude=${longitude}&latitude=${latitude}`,
    });

    return locations;
  },

  // this way we can update/improve this logic later on
  async findBestLocation({
    longitude,
    latitude,
  }: {
    longitude: number;
    latitude: number;
  }): Promise<CompactLocation> {
    const locations = await this.findByPosition({ longitude, latitude });

    return locations[0];
  },
};
