import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

import { fetchOrder } from './fetchOrder';
import { countOrders } from './countOrders';
import { fetchOrders } from './fetchOrders';
import { clearOrders } from './clearOrders';
import { addOrderMsg } from './addOrderMsg';
import { saveOrderMsgDraft } from './saveOrderMsgDraft';
import { archiveOrder } from './archiveOrder';
import { fetchPaidOutOrders } from './fetchPaidOutOrders';

const PAGE_SIZE = 25;

@Injectable()
export class OrderActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchOrder = fetchOrder(this.ngRedux.dispatch);
  countOrders = countOrders(this.ngRedux.dispatch);
  fetchOrders = fetchOrders(this.ngRedux.dispatch);
  clearOrders = clearOrders(this.ngRedux.dispatch);
  addOrderMsg = addOrderMsg(this.ngRedux.dispatch);
  saveOrderMsgDraft = saveOrderMsgDraft(this.ngRedux.dispatch);
  archiveOrder = archiveOrder(this.ngRedux.dispatch);
  fetchPaidOutOrders = fetchPaidOutOrders(this.ngRedux.dispatch);

  refreshOrders(partitionedOrders, silent) {
    this.countOrders(silent);

    partitionedOrders?.forEach((filters, role) => {
      if (['vendor', 'customer'].includes(role)) {
        // "status" is also in partitionedOrders
        filters.forEach((orders, filter) => {
          this.fetchOrders(role, filter, 0, Math.max(orders.size, PAGE_SIZE), silent);
        });
      }
    });
  }
}
