import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { bootstrapAPI } from '../../webapi/v2';
import { Dispatch } from 'redux';

// Success.
const setSuccessState = (response) => {
  return {
    data: fromJS({
      response: response,
      status: 'SUCCESS'
    }),
    type: actionTypes.BOOTSTRAP_FETCH_SUCCESS
  };
};

// Error.
const setErrorState = (error) => {
  return {
    data: fromJS({
      response: error,
      status: 'ERROR'
    }),
    type: actionTypes.BOOTSTRAP_FETCH_FAIL
  };
};

const setLoadingState = () => {
  return {
    data: fromJS({
      response: [],
      status: 'LOADING'
    }),
    type: actionTypes.BOOTSTRAP_FETCH_INIT
  };
};

export const fetchBootstrap = (dispatch: Dispatch<any>) => () => {
  dispatch(setLoadingState());
  bootstrapAPI
    .fetchBootstrap()
    .then((response): any => dispatch(setSuccessState(response)))
    .catch((err): any => dispatch(setErrorState(err)));
};
