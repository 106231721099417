import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';

import { fetchBootstrap } from './fetchBootstrap';

@Injectable()
export class BootstrapActions {
  constructor(private ngRedux: NgRedux<any>) {}

  fetchBootstrap = fetchBootstrap(this.ngRedux.dispatch);
}
