import { Injectable } from '@angular/core';

import { Angulartics2 } from 'angulartics2';
import { environment } from '../../environments/environment';

import { buildUrl } from '../../webapi/getAPIURI';
import request from '../../utils/request';
// import * as criteoTracker from './criteo-tracker.service';
import { DeviceIdService } from './device-id.service';

@Injectable()
export class Angulartics2HyggloTracking {
  constructor(public angulartics2: Angulartics2, private deviceId: DeviceIdService) {
    this.angulartics2.eventTrack.subscribe(({ action, properties }) =>
      this.eventTrack(action, properties)
    );

    this.angulartics2.pageTrack.subscribe(({ path }) => {
      this.eventTrack('Page View', { path });

      // track urls such as /?utm_term=foo as homepage
      // if (path.split('?')[0] === '/') {
      //   criteoTracker.viewHome();
      // }
    });
  }

  eventTrack(event: string, data: any) {
    if (!environment.production) {
      console.log(event, data);
    }
    /* Make sure tracking happens _after_ page load */
    setTimeout(() => {
      request(
        {
          uri: buildUrl(`/v2/track`),
          method: 'POST',
          json: {
            event,
            data,
            deviceId: this.deviceId.get(),
            meta: {
              referer: document.referrer || null
            }
          }
        },
        true,
        false,
        false
      );
    }, 6 * 1000);
  }
}
