import { NgModule } from '@angular/core';
import {
  // MatAutocompleteModule,
  MatButtonModule
} from '@angular/material/button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import {
//   // MatCardModule,
//   MatCheckboxModule
// } from '@angular/material/checkbox';
// import {
// MatChipsModule,
// MatDatepickerModule,
//   MatDialogModule
// } from '@angular/material/dialog';
// import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  // MatGridListModule,
  MatIconModule
} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
// import {
// MatNativeDateModule,
// MatPaginatorModule,
//   MatProgressBarModule
// } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatRadioModule } from '@angular/material/radio';
import {
  // MatRippleModule,
  MatSelectModule
} from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { MatSliderModule } from '@angular/material/slider';
// import {
// MatTableModule,
//   MatTabsModule
// } from '@angular/material/tabs';
// import {
// MatToolbarModule,
// MatTooltipModule
// MatStepperModule,
// } from '@angular/material/tooltip';
// import { CdkTableModule } from '@angular/cdk/table';
// import { A11yModule } from '@angular/cdk/a11y';
// import { BidiModule } from '@angular/cdk/bidi';
// import { OverlayModule } from '@angular/cdk/overlay';
// import { PlatformModule } from '@angular/cdk/platform';
// import { ObserversModule } from '@angular/cdk/observers';
// import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  exports: [
    // MatAutocompleteModule,
    MatButtonModule,
    // MatButtonToggleModule,
    // MatCardModule,
    // MatCheckboxModule,
    // MatChipsModule,
    // MatTableModule,
    // MatDatepickerModule,
    // MatDialogModule,
    // MatExpansionModule,
    MatFormFieldModule,
    // MatGridListModule,
    MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    // MatPaginatorModule,
    // MatProgressBarModule,
    // MatProgressSpinnerModule,
    // MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    MatSidenavModule
    // MatSlideToggleModule,
    // MatSliderModule,
    // MatSnackBarModule,
    // MatSortModule,
    // MatTabsModule
    // MatToolbarModule,
    // MatTooltipModule
    // MatNativeDateModule,
    // MatStepperModule,
    // CdkTableModule,
    // A11yModule,
    // BidiModule,
    // ObserversModule,
    // OverlayModule,
    // PlatformModule,
    // PortalModule
  ]
})
export class MaterialModule {}
