import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const productAPI = {
  fetchMyProduct(id: number, silent?: boolean) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl(`/v2/my/products/${id}`);
      const options = {
        responseType: 'json',
        uri
      };
      request(options, silent)
        .then((response: any) => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  publishProduct(productData: any) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/my/products');
      const options = {
        body: productData,
        uri,
        method: 'POST',
        intent: 'publish product'
      };
      request(options)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  },

  updateProduct(id: number, productData: any) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl(`/v2/my/products/${id}`);
      const options = {
        body: productData,
        uri,
        method: 'PUT'
      };
      request(options)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  },

  updateProductStatus(id: number, published: boolean) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl(`/v2/my/products/${id}`);
      const body = {
        id,
        isPublished: published
      };
      const headers = {
        'Content-Type': 'application/json'
      };

      const options = {
        headers,
        json: body,
        uri,
        method: 'PATCH'
      };

      request(options)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  fetchMyProducts(silent?: boolean) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/my/products');
      const options = {
        uri,
        responseType: 'json',
        intent: 'fetch my products'
      };
      request(options, silent)
        .then((response: any): any => resolve(response.body))
        .catch((err): any => reject(err));
    });
  },

  setAsFavorite(id: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl('/v2/my/product-listing-favorites');

      const body = {
        productListingId: id
      };

      const options = {
        json: body,
        uri,
        method: 'POST',
        intent: 'set as favorite'
      };
      request(options, true)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  },

  removeAsFavorite(id: string) {
    return new Promise((resolve, reject) => {
      const uri = buildUrl(`/v2/my/product-listing-favorites`, { productListingId: id });

      const options = {
        uri,
        method: 'DELETE',
        intent: 'remove as favorite'
      };
      request(options, true)
        .then((response): any => resolve(response))
        .catch((err): any => reject(err));
    });
  }
};

export default productAPI;
