import { fromJS } from 'immutable';
import actionTypes from '../types/actionTypes';
const initialState = fromJS({});

const users = (state = initialState, action = { type: undefined, data: undefined }) => {
  switch (action && action.type) {
    case actionTypes.FETCH_USER:
      return state.mergeIn([action.data.get('userId')], action.data);

    case actionTypes.FETCH_REVIEWS:
      return state.mergeIn([action.data.get('userId'), 'reviews'], action.data);

    case actionTypes.FETCH_PRODUCTS_BY_USER:
      return state.mergeIn([action.data.get('userId'), 'products'], action.data);

    case actionTypes.CLEAR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default users;
