import actionTypes from '../../types/actionTypes';
import { fromJS } from 'immutable';
import { privateOrderAPI } from '../../webapi/private/index';
import { Dispatch } from 'redux';

export const fetchOrder = (dispatch: Dispatch<any>) => async (id: string, silent?: boolean) => {
  const setLoadingState = () => ({
    data: fromJS({ id, status: 'LOADING' }),
    type: actionTypes.ORDER_FETCH_INIT,
  });

  const setErrorState = (error) => ({
    data: fromJS({ id, status: 'ERROR' }),
    type: actionTypes.ORDER_FETCH_FAIL,
  });

  const setSuccessState = (response) => ({
    data: fromJS({ id, response, status: 'SUCCESS' }),
    type: actionTypes.ORDER_FETCH_SUCCESS,
  });

  dispatch(setLoadingState());
  try {
    const response = await privateOrderAPI.fetchOrder(id, silent);
    return dispatch(setSuccessState(response));
  } catch (err) {
    return dispatch(setErrorState(err));
  }
};
