import { buildUrl } from '../getAPIURI';
import request from '../../utils/request';

export const campaignSignupAPI = {
  signUp({ campaignName, campaignCode }: { campaignName: string; campaignCode: string }) {
    const uri = buildUrl(`/v2/my/campaign-signup/`);

    const options = {
      json: { campaignCode, campaignName },
      uri,
      method: 'POST'
    };

    return request(options, false);
  },
  async hasSignedUpAlready({ campaignName }: { campaignName: string }) {
    const uri = buildUrl(`/v2/my/campaign-signup/?campaignName=${campaignName}`);
    const options = { uri, method: 'GET' };

    const { body } = await request(options, false);
    const json = JSON.parse(body);

    return json.length > 0;
  }
};
